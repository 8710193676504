import { styled } from '@slal/ui';
import { Button } from '@slal/ui/buttons';
import { breakpointUp } from '@slal/ui/helpers';
import { P, Text } from '@slal/ui/typography';

export const StyledPhoneNumber = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes[6]};
`;

export const StyledOpeningHours = styled.div`
    margin: ${({ theme }) => theme.space[4]} 0 0;
    font-size: ${({ theme }) => theme.fontSizes[6]};
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
    p {
        margin: 0;
        font-size: ${({ theme }) => theme.fontSizes[3]};
    }
`;

export const StyledApplicationButtonsWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
`;

export const EstimatedCompletionTime = styled(Text)`
    display: flex;
    color: ${({ theme }) => theme.colors.neutral__dark__20};
    align-items: center;
    gap: ${({ theme }) => theme.space[4]};
    flex-shrink: 0;
    margin-left: ${({ theme }) => theme.space[5]};
`;

export const StyledStartApplicationButton = styled(Button)`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledHelpPhoneNumber = styled(P)`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
    padding-top: ${({ theme }) => theme.space[4]};
    font-weight: ${({ theme }) => theme.fonts.headings.fontWeights.regular};
`;

export const StyledStartApplicationWrapper = styled.div`
    width: 100%;
    ${breakpointUp.sm`
        width: 350px;

    `}
`;

export const StyledBackButton = styled(Button)`
    width: 100%;
`;

export const StyledStartButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.space[7]};
    padding-bottom: ${({ theme }) => theme.space[5]};
    align-items: center;
`;
