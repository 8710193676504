import { styled } from '@slal/ui';
import { P, Text } from '@slal/ui/typography';

export const StyledPhoneNumber = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes[6]};
`;

export const StyledOpeningHours = styled.div`
    margin: ${({ theme }) => theme.space[4]} 0 0;
    font-size: ${({ theme }) => theme.fontSizes[6]};
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
    p {
        margin: 0;
        font-size: ${({ theme }) => theme.fontSizes[3]};
    }
`;

export const StyledHelpPhoneNumber = styled(P)`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
    padding-top: ${({ theme }) => theme.space[4]};
    font-weight: ${({ theme }) => theme.fonts.headings.fontWeights.regular};
`;
