import { styled } from '@slal/ui';
import { FieldSet } from '@slal/ui/react-hook-form/fields';
import { Notification } from '~/components';

export const StyledNotification = styled(Notification)`
    margin-left: 44px;
`;

export const StyledFieldSet = styled(FieldSet)`
    margin-bottom: ${({ theme: { space } }) => space[4]};
`;
