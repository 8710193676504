import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';

export const StyledHeadingWrapper = styled.div`
    padding-top: ${({ theme }) => theme.space[9]};
    padding-bottom: ${({ theme }) => theme.space[9]};
    ${breakpointUp.md`
        padding-top: ${({ theme }) => theme.space[10]};
        padding-bottom: ${({ theme }) => theme.space[10]};  
    `}

    background-color: ${({ theme }) => theme.colors.blue__primary__70};
    margin-bottom: ${({ theme }) => theme.space[6]};
    align-items: center;
    justify-content: center;
    display: flex;
`;
