import { css, styled } from '@slal/ui';
import { StyledRadioLabel, TextField as SUITextField } from '@slal/ui/complete-forms';
import { ErrorIcon } from '@slal/ui/icons';
import { Article, P, Text } from '@slal/ui/typography';
import { Input } from '@slal/ui/forms';
import { FieldWrapperProps } from './types';

export const relatedFieldIndent = '44px';

export const StyledForm = styled.form``;

export const StyledFieldSet = styled.fieldset`
    margin: 0;
    padding: 0;
    border: 0;
`;

export const FieldWrapper = styled.div<FieldWrapperProps>`
    margin-bottom: ${({ theme }) => theme.space[6]};

    > label {
        margin-bottom: ${({ theme }) => theme.space[4]};
        font-family: ${({ theme }) => theme.fonts.headings.family};
        font-size: ${({ theme }) => theme.fontSizes[4]};
        font-weight: 500;
    }

    input[type='checkbox'] {
        margin-right: ${({ theme }) => theme.space[4]};
    }

    ${StyledRadioLabel} {
        margin-bottom: ${({ theme }) => theme.space[4]};
        font-size: inherit;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
        line-height: 1.4;
        > input[type='radio'] {
            width: ${({ theme }) => theme.space[7]};
            min-width: ${({ theme }) => theme.space[7]};
            height: ${({ theme }) => theme.space[7]};
            margin-right: ${({ theme }) => theme.space[4]};
            border-width: 3px;
            &:after {
                width: ${({ theme }) => theme.space[5]};
                height: ${({ theme }) => theme.space[5]};
            }
            &:focus,
            &:focus-within {
                outline-offset: ${({ theme }) => theme.space[2]};
                outline-width: ${({ theme }) => theme.space[2]};
                outline-style: auto;
                outline-color: ${({ theme }) => theme.colors.aqua__80};
            }
        }
    }

    ${({ theme, variant }) =>
        variant === 'related' &&
        css`
            margin-top: -${theme.space[7]};
            margin-left: ${relatedFieldIndent};
            > label {
                font-family: ${theme.fonts.sansSerif.family};
                font-weight: ${theme.fonts.sansSerif.fontWeights.regular};
                color: ${theme.colors.neutral__light__70};
            }
        `}
`;

export const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[3]};
`;

export const FieldErrorMessage = styled(P)`
    margin-top: ${({ theme }) => theme.space[2]};
    color: ${({ theme }) => theme.colors.danger};
    font-size: ${({ theme }) => theme.fontSizes[3]};
`;

export const StyledErrorIcon = styled(ErrorIcon)`
    color: ${({ theme }) => theme.colors.danger};
    margin-right: ${({ theme }) => theme.space[3]};
`;

export const StyledSubmitButtonWrapper = styled.div`
    display: flex;
    margin-top: ${({ theme }) => theme.space[7]};
    padding-bottom: ${({ theme }) => theme.space[7]};
`;

export const StyledFieldHelp = styled(Text)`
    display: block;
    margin-top: ${({ theme }) => theme.space[4]};
    font-size: ${({ theme }) => theme.fontSizes[3]};
    font-family: ${({ theme }) => theme.fonts.sansSerif.family};
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
`;

StyledFieldHelp.defaultProps = {
    tag: 'span',
};

export const StyledFormIntroduction = styled(Article)`
    margin-bottom: ${({ theme }) => theme.space[5]};
    p {
        margin-top: -${({ theme }) => theme.space[5]};
    }
`;

const getSize = (size?: number) => {
    if (!size) return '100%';
    switch (size) {
        case 4:
            return '80px';
        case 3:
            return '68px';
        case 2:
            return '56px';
        default:
            return '44px';
    }
};

export const StyledInput = styled(Input)`
    width: ${({ size }) => getSize(size)};
    margin-right: ${({ size, theme }) => (size ? theme.space[4] : '0')};
`;

export const StyledDateFieldSet = styled(StyledFieldSet)`
    padding-top: ${({ theme }) => theme.space[2]};
    margin: 0;
    label {
        margin-bottom: ${({ theme }) => theme.space[4]};
        font-size: inherit;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
`;

export const TextField = styled(SUITextField)`
    width: ${({ size }) => getSize(size)};
`;

export const HeadingLabelWrapper = styled.div``;

export const HeadingLabelTitle = styled(Text)``;

export const HeadingLabelDescription = styled(Text)``;
