import { Amplify } from 'aws-amplify';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';

Amplify.configure({
    API: {
        REST: {
            [`${import.meta.env.VITE_REST_API_NAME}`]: {
                endpoint: import.meta.env.VITE_REST_API_URL,
                region: import.meta.env.VITE_AWS_DEFAULT_REGION,
            },
        },
    },
    Auth: {
        Cognito: {
            allowGuestAccess: true,
            identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
        },
    },
});

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <App />
    </StrictMode>
);
