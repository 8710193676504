import { InvestmentOptions } from '~/types';
import { HeadingLabel } from '~/components';

export const options = [
    {
        label: (
            <HeadingLabel
                title="Ready made"
                description="Our easy option, designed by experts with the majority of pension savers in mind"
            />
        ),
        value: InvestmentOptions.DEFAULT,
    },
    {
        label: (
            <HeadingLabel title="Choose your own investments" description="Choose from over 300 investment options" />
        ),
        value: InvestmentOptions.CUSTOM,
    },
];

export const initialValues = {
    savingsInvestment: {
        option: '',
    },
};
