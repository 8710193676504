import { Path, Svg } from '@react-pdf/renderer';
import { theme } from '@slal/ui/theme';

export const StandardLifeGroupLogo = () => (
    <Svg width="138" height="43" viewBox="0 0 138 43" fill="none">
        <Path d="M126.295 8.66831L129.836 15.4018L138 0L126.295 8.66831Z" fill={theme.colors.yellow__70} />
        <Path
            d="M5.09806 25.5091C5.50139 25.5177 5.9043 25.4788 6.29799 25.3934C6.56838 25.3328 6.82471 25.2238 7.05375 25.0718C7.2286 24.9543 7.36449 24.7901 7.44489 24.5991C7.52036 24.4016 7.55745 24.1922 7.55427 23.9817C7.55205 23.7402 7.48673 23.5032 7.36441 23.2928C7.24209 23.0825 7.06678 22.9057 6.85487 22.779C6.1001 22.3347 5.2889 21.9877 4.44174 21.7467C3.94122 21.5763 3.44732 21.3833 2.9468 21.1614C2.46539 20.9493 2.01867 20.6698 1.6209 20.3318C1.2187 19.9938 0.889699 19.5818 0.653002 19.1195C0.390084 18.577 0.262883 17.9821 0.281752 17.383C0.267363 16.748 0.39752 16.1177 0.662946 15.5371C0.921412 14.9949 1.30471 14.5174 1.78333 14.1415C2.30323 13.74 2.89884 13.4407 3.53682 13.2604C4.28943 13.0416 5.0715 12.9333 5.85713 12.9388C6.75227 12.9242 7.64503 13.0325 8.50892 13.2604C9.15213 13.4391 9.77428 13.6827 10.3652 13.9871L9.37075 16.6047C8.9159 16.3749 8.44254 16.1813 7.95536 16.0259C7.34688 15.8503 6.71389 15.7679 6.07922 15.7815C5.26158 15.7815 4.67267 15.8887 4.31247 16.1031C4.13745 16.2088 3.99515 16.3585 3.90062 16.5362C3.80609 16.714 3.76285 16.9132 3.77548 17.1128C3.76737 17.3581 3.83803 17.5997 3.97768 17.8042C4.12835 18.0049 4.31881 18.1743 4.53787 18.3027C4.80166 18.4644 5.0815 18.6002 5.37318 18.7078C5.68808 18.83 6.03613 18.9522 6.41401 19.0809C7.11534 19.3269 7.80346 19.607 8.47577 19.9202C9.01362 20.1629 9.50731 20.4887 9.93757 20.8849C10.3203 21.2465 10.6157 21.6857 10.8027 22.1712C11.0062 22.7538 11.1027 23.3666 11.0878 23.9817C11.0878 25.358 10.5906 26.4299 9.59615 27.1974C8.60173 27.9649 7.10237 28.3454 5.09806 28.339C4.48937 28.3432 3.88118 28.3045 3.27827 28.2232C2.79334 28.1554 2.31296 28.0599 1.83968 27.937C1.47353 27.8399 1.11469 27.7185 0.765703 27.5736C0.503384 27.4671 0.247713 27.3458 0 27.2103L0.964587 24.5733C1.49889 24.8442 2.06106 25.0597 2.64184 25.2165C3.44225 25.426 4.26919 25.5245 5.09806 25.5091Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M12.3223 14.0659L15.481 13.5535V16.86H19.2703V19.5059H15.481V23.4506C15.4526 24.0094 15.573 24.5657 15.8299 25.0619C16.0635 25.4583 16.5308 25.6582 17.238 25.6582C17.5901 25.6569 17.9413 25.6246 18.2878 25.5615C18.6238 25.4984 18.9529 25.4024 19.2703 25.2746L19.7151 27.7497C19.2996 27.9184 18.8715 28.0542 18.435 28.1558C17.8783 28.2813 17.3092 28.3418 16.7388 28.3363C16.0103 28.3598 15.2842 28.2405 14.6009 27.985C14.0748 27.7832 13.608 27.4506 13.244 27.0182C12.8924 26.5727 12.6491 26.0507 12.5335 25.4938C12.3932 24.8417 12.3256 24.1758 12.3319 23.5086L12.3223 14.0659Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M25.2051 16.6367C26.0151 16.6154 26.8233 16.7239 27.5974 16.9579C28.1767 17.1346 28.7026 17.4479 29.1288 17.8701C29.5191 18.2787 29.8002 18.7752 29.947 19.3155C30.1123 19.933 30.193 20.5692 30.1869 21.2074V27.7857C29.7203 27.8864 29.0751 28.0041 28.2514 28.139C27.2571 28.2827 26.2528 28.3493 25.2478 28.3382C24.5724 28.3454 23.8982 28.2819 23.2367 28.1487C22.681 28.0346 22.1532 27.8164 21.6823 27.5063C21.2572 27.2171 20.9173 26.8239 20.6965 26.366C20.4458 25.8336 20.3211 25.2528 20.3317 24.6668C20.3131 24.089 20.4527 23.5168 20.7359 23.0094C20.994 22.5642 21.3638 22.1905 21.8105 21.9237C22.2885 21.6462 22.8105 21.4486 23.355 21.3391C23.9495 21.2184 24.5552 21.1581 25.1624 21.1592C25.5355 21.1574 25.9085 21.1756 26.2797 21.2138C26.5482 21.2381 26.8142 21.2843 27.0749 21.352V21.0532C27.0841 20.8173 27.0446 20.5819 26.9588 20.3612C26.873 20.1405 26.7426 19.9389 26.5754 19.7684C26.2468 19.4472 25.6619 19.2802 24.8338 19.2802C24.2839 19.2792 23.7347 19.3179 23.1907 19.3958C22.7165 19.4576 22.2498 19.5652 21.7973 19.717L21.3833 17.1666C21.5739 17.1088 21.8105 17.0478 22.0931 16.9836C22.3757 16.9225 22.6813 16.8647 23.0165 16.8133C23.3517 16.7619 23.7 16.7234 24.0714 16.688C24.4427 16.6527 24.8272 16.6367 25.2051 16.6367ZM25.4647 25.9067C25.7933 25.9067 26.0891 25.9067 26.3782 25.8874C26.6115 25.8778 26.844 25.8563 27.0749 25.8231V23.4077C26.8805 23.3691 26.6841 23.3401 26.4867 23.321C26.2489 23.2939 26.0097 23.2799 25.7703 23.2792C25.4824 23.2786 25.1948 23.2969 24.9093 23.3338C24.6646 23.3634 24.4261 23.4306 24.2028 23.5329C24.0105 23.6248 23.8462 23.7643 23.7263 23.9377C23.6024 24.1293 23.5416 24.3536 23.5522 24.5801C23.5311 24.7812 23.5676 24.9841 23.6575 25.1661C23.7474 25.3481 23.8873 25.5022 24.0615 25.6112C24.497 25.8207 24.98 25.918 25.4647 25.8938V25.9067Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M32.6523 17.2561C33.3523 17.0754 34.0629 16.934 34.7804 16.8328C35.6852 16.6994 36.5996 16.6339 37.5151 16.6367C38.3384 16.6141 39.1588 16.7365 39.9351 16.9977C40.53 17.2064 41.0577 17.5582 41.4664 18.0186C41.8501 18.4758 42.1242 19.0069 42.2697 19.5749C42.4353 20.2365 42.5157 20.9148 42.5091 21.5948V27.7233H39.2727V21.9746C39.2727 20.9848 39.1361 20.2845 38.8628 19.8736C38.5896 19.4628 38.0759 19.2553 37.3217 19.2512C37.0889 19.2512 36.8429 19.2512 36.5839 19.2823C36.3249 19.3134 36.0888 19.3259 35.8887 19.3539V27.7233H32.6523V17.2561Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M54.2134 27.6812C53.9287 27.7704 53.5988 27.8529 53.2267 27.9322C52.8547 28.0114 52.4664 28.0808 52.062 28.1402C51.6576 28.1997 51.2403 28.2459 50.8197 28.2822C50.3991 28.3186 49.9947 28.3384 49.6097 28.3384C48.7638 28.3525 47.9222 28.2116 47.1251 27.9223C46.4333 27.6722 45.8056 27.2662 45.2887 26.7345C44.7717 26.2027 44.3787 25.559 44.139 24.8509C43.8638 24.0547 43.729 23.215 43.741 22.3707C43.7294 21.5157 43.8451 20.6639 44.084 19.8443C44.2856 19.1465 44.621 18.4966 45.0707 17.9322C45.4945 17.4104 46.0333 16.9984 46.643 16.7301C47.3166 16.442 48.0416 16.3003 48.7718 16.3139C49.1724 16.3085 49.5722 16.3528 49.9624 16.446C50.3249 16.5376 50.6789 16.6613 51.0203 16.8159V11.6145L54.2134 11.0894V27.6812ZM46.986 22.2056C46.9438 23.0667 47.1766 23.9185 47.6492 24.633C47.8783 24.9396 48.178 25.1838 48.5216 25.3439C48.8653 25.5041 49.2424 25.5752 49.6194 25.5511C49.9074 25.5511 50.1694 25.5511 50.4121 25.518C50.6547 25.485 50.8553 25.4718 51.0106 25.4421V19.5273C50.7695 19.3757 50.5063 19.2642 50.2309 19.197C49.9145 19.1092 49.5882 19.0648 49.2603 19.0649C47.7441 19.0671 46.986 20.114 46.986 22.2056Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M60.9293 16.6371C61.7434 16.614 62.5559 16.7225 63.334 16.9583C63.9129 17.1357 64.4388 17.4488 64.866 17.8704C65.2559 18.2784 65.5355 18.7752 65.6796 19.3158C65.8464 19.9329 65.9269 20.5693 65.9191 21.2077V27.7857C65.4566 27.8853 64.8103 28.0041 63.9868 28.139C62.9943 28.2829 61.9917 28.3495 60.9884 28.3382C60.3141 28.3454 59.641 28.2819 58.9806 28.1487C58.4259 28.0346 57.8989 27.8164 57.4289 27.5063C57.0045 27.2171 56.6651 26.8239 56.4447 26.366C56.1903 25.8344 56.0603 25.254 56.0641 24.6669C56.0456 24.0891 56.1849 23.517 56.4677 23.0095C56.7275 22.5649 57.0975 22.1915 57.5437 21.9239C58.0148 21.6447 58.5305 21.445 59.0692 21.3329C59.6627 21.2122 60.2674 21.1519 60.8735 21.153C61.2472 21.1512 61.6206 21.1694 61.9922 21.2077C62.2593 21.2319 62.5237 21.2781 62.7829 21.3458V21.0471C62.7928 20.811 62.7537 20.5755 62.668 20.3546C62.5823 20.1338 62.4517 19.9323 62.2842 19.7623C61.9496 19.4368 61.3711 19.2741 60.5487 19.2741C59.9998 19.2732 59.4516 19.3118 58.9084 19.3897C58.4361 19.4513 57.9712 19.5589 57.5207 19.7109L57.1074 17.1606C57.2944 17.1028 57.5306 17.0418 57.8127 16.9775C58.0948 16.9133 58.4032 16.8587 58.7346 16.8073C59.0659 16.7559 59.4202 16.7174 59.7876 16.682C60.1551 16.6467 60.552 16.6371 60.9293 16.6371ZM61.1917 25.9067C61.5198 25.9067 61.8118 25.9067 62.1038 25.8874C62.3355 25.8779 62.5665 25.8564 62.796 25.8232V23.4078C62.603 23.3692 62.4081 23.3402 62.212 23.3211C61.6892 23.2571 61.1602 23.2571 60.6373 23.3211C60.3928 23.35 60.1547 23.4173 59.932 23.5202C59.7384 23.6111 59.5731 23.7508 59.453 23.925C59.3308 24.1173 59.2703 24.341 59.2791 24.5673C59.2581 24.7688 59.2948 24.972 59.3852 25.1541C59.4756 25.3363 59.6161 25.4901 59.7909 25.5984C60.2243 25.8127 60.7067 25.9145 61.1917 25.8939V25.9067Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M74.6186 19.5861C74.3281 19.5174 73.9919 19.4425 73.6035 19.3677C73.1906 19.2908 72.7708 19.2532 72.3502 19.2553C72.1078 19.2575 71.8658 19.2741 71.6256 19.3052C71.4104 19.3267 71.1975 19.3654 70.9891 19.4207V27.7233H67.7676V17.4269C68.4436 17.214 69.1311 17.0359 69.8271 16.8934C70.6847 16.7142 71.5605 16.6274 72.4383 16.6344C72.6113 16.6344 72.8202 16.6344 73.065 16.6656C73.3098 16.6968 73.5546 16.7156 73.7994 16.7468C74.0442 16.778 74.289 16.8248 74.5338 16.8716C74.7482 16.9112 74.9581 16.9708 75.1604 17.0494L74.6186 19.5861Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M86.2506 27.6814C85.9626 27.7705 85.6359 27.8531 85.2638 27.9324C84.8918 28.0116 84.5035 28.081 84.0959 28.1404C83.6883 28.1999 83.2774 28.2461 82.8568 28.2824C82.4362 28.3188 82.0318 28.3386 81.6436 28.3386C80.802 28.3513 79.9649 28.2103 79.1719 27.9224C78.4788 27.6682 77.8492 27.2611 77.3278 26.7302C76.814 26.1954 76.4199 25.5532 76.1728 24.8478C75.9003 24.0509 75.7667 23.2115 75.7781 22.3676C75.7665 21.5125 75.8822 20.6607 76.1211 19.8411C76.3177 19.1445 76.6476 18.4946 77.0916 17.9289C77.5172 17.4076 78.0569 16.9958 78.6672 16.7268C79.3408 16.4388 80.0658 16.297 80.796 16.3107C81.1966 16.3054 81.5964 16.3498 81.9865 16.4428C82.3525 16.5348 82.7097 16.6597 83.0542 16.816V11.6145L86.2473 11.0894L86.2506 27.6814ZM79.0328 22.2057C78.9907 23.0668 79.2234 23.9186 79.696 24.6331C79.9251 24.9397 80.2248 25.1839 80.5685 25.3441C80.9121 25.5042 81.2892 25.5754 81.6662 25.5512C81.9509 25.5512 82.2162 25.5512 82.4589 25.5182C82.7015 25.4852 82.9021 25.472 83.0574 25.4422V19.5274C82.8163 19.3758 82.5531 19.2643 82.2777 19.1971C81.9614 19.1093 81.635 19.0649 81.3071 19.065C79.7909 19.0672 79.0328 20.1141 79.0328 22.2057Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M101.652 24.8641V27.7232H91.7949V12.9375H95.115V24.8641H101.652Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M107.195 13.5472C107.21 13.7988 107.16 14.0503 107.05 14.2834C106.939 14.5166 106.77 14.7258 106.555 14.8959C106.135 15.2119 105.598 15.3859 105.041 15.3859C104.484 15.3859 103.947 15.2119 103.527 14.8959C103.312 14.7258 103.143 14.5166 103.033 14.2834C102.922 14.0503 102.872 13.7988 102.887 13.5472C102.872 13.2955 102.922 13.0441 103.033 12.8109C103.143 12.5777 103.312 12.3686 103.527 12.1984C103.946 11.8806 104.484 11.7053 105.041 11.7053C105.598 11.7053 106.136 11.8806 106.555 12.1984C106.77 12.3686 106.939 12.5777 107.05 12.8109C107.16 13.0441 107.21 13.2955 107.195 13.5472ZM106.858 27.7232H103.249V16.7248H106.858V27.7232Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M113.515 11.0896C114.093 11.0851 114.668 11.1459 115.23 11.2709C115.641 11.3596 116.045 11.4787 116.437 11.6271L115.79 14.1754C115.479 14.0447 115.153 13.9481 114.819 13.8872C114.5 13.8255 114.175 13.793 113.849 13.7901C113.504 13.7814 113.161 13.8364 112.838 13.952C112.587 14.0418 112.364 14.1923 112.191 14.3891C112.029 14.5846 111.914 14.8125 111.854 15.0562C111.785 15.3295 111.751 15.6102 111.753 15.8916V16.4485H115.857V19.1069H111.753V27.7233H108.428V15.8365C108.428 14.3773 108.856 13.2191 109.711 12.3621C110.567 11.5051 111.835 11.081 113.515 11.0896Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M116.439 22.5596C116.42 21.6752 116.575 20.7957 116.894 19.9704C117.167 19.2803 117.577 18.6518 118.098 18.1218C118.584 17.6361 119.165 17.2547 119.805 17.0012C120.434 16.756 121.105 16.6315 121.781 16.6341C123.363 16.6341 124.612 17.1172 125.531 18.0835C126.449 19.0498 126.909 20.4684 126.911 22.3393C126.911 22.5244 126.911 22.7256 126.888 22.9427C126.866 23.1598 126.859 23.3545 126.847 23.5237H119.68C119.708 23.832 119.805 24.1301 119.963 24.3967C120.121 24.6633 120.336 24.8918 120.592 25.0658C121.236 25.4758 121.991 25.6756 122.754 25.6372C123.327 25.6374 123.898 25.584 124.461 25.4776C124.93 25.3993 125.389 25.2686 125.828 25.0881L126.251 27.6422C126.033 27.7452 125.807 27.8296 125.575 27.8944C125.26 27.9869 124.939 28.0615 124.615 28.1179C124.269 28.1818 123.897 28.2328 123.503 28.2743C123.109 28.3179 122.712 28.3392 122.315 28.3382C121.422 28.3547 120.534 28.2008 119.699 27.8848C119.003 27.6202 118.375 27.206 117.858 26.6717C117.364 26.1503 116.994 25.5252 116.775 24.8423C116.541 24.1042 116.428 23.3335 116.439 22.5596ZM123.862 21.3559C123.847 21.0907 123.801 20.8282 123.724 20.5738C123.649 20.327 123.526 20.0972 123.363 19.8969C123.193 19.6947 122.984 19.5295 122.748 19.4116C122.46 19.275 122.144 19.2093 121.825 19.2201C121.511 19.2123 121.2 19.2744 120.913 19.4021C120.667 19.5125 120.449 19.6749 120.272 19.8778C120.099 20.0797 119.965 20.3126 119.878 20.5642C119.789 20.822 119.722 21.0869 119.677 21.3559H123.862Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M1.39775 35.7373C1.99458 35.7034 2.58838 35.8388 3.10035 36.1255C3.29681 36.2577 3.45419 36.4337 3.55792 36.6372C3.66165 36.8408 3.7084 37.0654 3.69382 37.2901C3.7027 37.5395 3.6471 37.7871 3.53167 38.0124C3.42401 38.2106 3.26178 38.3788 3.06143 38.5C2.8329 38.6321 2.58002 38.7239 2.31553 38.7708C1.98741 38.8289 1.65395 38.8571 1.31992 38.8551H0.73617V40.6607H0V35.8456C0.228352 35.7988 0.460478 35.7696 0.694011 35.7583C0.946968 35.7583 1.18371 35.7373 1.39775 35.7373ZM1.45937 36.3392C1.13506 36.3392 0.904809 36.3392 0.73617 36.3602V38.2742H1.29073C1.51975 38.2758 1.74854 38.2607 1.97501 38.2291C2.15308 38.2059 2.3254 38.154 2.48417 38.0756C2.6209 38.0059 2.7334 37.9016 2.80847 37.7747C2.88971 37.6246 2.92876 37.458 2.92198 37.2901C2.92844 37.1288 2.88812 36.9687 2.80523 36.8267C2.72774 36.7015 2.61583 36.5977 2.48093 36.5257C2.33709 36.45 2.18017 36.3981 2.01717 36.3723C1.83267 36.3454 1.64613 36.3323 1.45937 36.3331V36.3392Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M5.88198 36.9651C6.126 36.9589 6.36925 36.9924 6.59993 37.0638C6.77578 37.1189 6.93352 37.2123 7.05887 37.3355C7.18423 37.4588 7.27325 37.6081 7.31787 37.7699C7.37063 37.949 7.39576 38.1337 7.39259 38.319V40.5241C7.33412 40.5241 7.24965 40.5474 7.1392 40.5619C7.02874 40.5764 6.90855 40.5939 6.7721 40.6084C6.63566 40.6229 6.48622 40.6345 6.32704 40.6433C6.16786 40.652 6.00218 40.6607 5.85274 40.6607C5.64502 40.6623 5.43782 40.6418 5.2355 40.5997C5.06199 40.5639 4.89771 40.4988 4.75146 40.4079C4.61212 40.3168 4.50045 40.1959 4.4266 40.0564C4.3469 39.8915 4.30811 39.713 4.3129 39.5334C4.30803 39.3584 4.35392 39.1852 4.44609 39.0308C4.53267 38.8923 4.65664 38.7755 4.80669 38.6909C4.9714 38.602 5.15143 38.5382 5.33946 38.502C5.55277 38.4598 5.77073 38.4394 5.98919 38.441H6.21009C6.28806 38.441 6.35953 38.4584 6.42775 38.4671L6.60642 38.4991L6.71363 38.5194V38.3422C6.71369 38.2386 6.70061 38.1353 6.67464 38.0342C6.65014 37.9359 6.60374 37.8431 6.5382 37.7611C6.46705 37.6793 6.37444 37.6145 6.26857 37.5723C6.12807 37.5189 5.97605 37.4941 5.82351 37.4997C5.61886 37.4974 5.41443 37.512 5.21276 37.5432C5.07903 37.5642 4.94753 37.5953 4.81968 37.6362L4.73847 37.1132C4.88472 37.0619 5.03746 37.0268 5.19327 37.0086C5.4209 36.9755 5.65141 36.961 5.88198 36.9651ZM5.94046 40.1319C6.10938 40.1319 6.26532 40.1319 6.38552 40.1319C6.4947 40.1284 6.60336 40.1167 6.71038 40.0971V39.0424C6.63129 39.0134 6.54828 38.9939 6.46348 38.9843C6.32564 38.968 6.1867 38.9602 6.04766 38.9611C5.93899 38.9615 5.83047 38.9683 5.7228 38.9814C5.6102 38.9939 5.50054 39.0223 5.39794 39.0656C5.3026 39.1072 5.21924 39.1679 5.15429 39.2429C5.08454 39.3266 5.04899 39.4291 5.05358 39.5334C5.04251 39.6243 5.05935 39.7161 5.10228 39.799C5.14521 39.882 5.21261 39.9529 5.29723 40.0041C5.49495 40.0975 5.71711 40.1416 5.94046 40.1319Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M9.6087 36.9645H9.83661L10.0895 36.9943L10.3209 37.027L10.4741 37.0598L10.3423 37.6222C10.2782 37.6044 10.1785 37.5835 10.036 37.5568C9.85635 37.5291 9.67387 37.5162 9.49119 37.5181C9.34755 37.5189 9.20438 37.5319 9.06385 37.5568L8.78253 37.6103V40.6608H8.00977V37.1937C8.23211 37.1287 8.45932 37.076 8.68994 37.0359C8.99248 36.9861 9.30034 36.9621 9.6087 36.9645Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M11.8111 36.8776H13.3867V37.4427H11.8111V39.1471C11.8075 39.3013 11.8247 39.4554 11.8624 39.6059C11.8843 39.7098 11.9335 39.8077 12.0059 39.8915C12.0684 39.9587 12.1515 40.0083 12.2452 40.0343C12.3553 40.067 12.4709 40.0824 12.5869 40.0799C12.7701 40.0848 12.9529 40.0611 13.1269 40.01L13.4106 39.9158L13.5541 40.4627C13.4265 40.5123 13.2952 40.5539 13.1611 40.5872C12.9531 40.6385 12.7379 40.663 12.522 40.6602C12.2896 40.6656 12.0581 40.6337 11.8385 40.566C11.6636 40.5075 11.5099 40.4077 11.3942 40.2773C11.2759 40.1386 11.1952 39.9774 11.1584 39.8064C11.1105 39.5924 11.0876 39.3745 11.09 39.1562V35.8446L11.8316 35.7322L11.8111 36.8776Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M19.0985 38.814C19.1026 39.0718 19.0566 39.3283 18.9626 39.5719C18.8759 39.7854 18.7453 39.9824 18.5775 40.1526C18.4154 40.3148 18.2154 40.4427 17.9917 40.5272C17.757 40.6154 17.5051 40.6608 17.2506 40.6608C16.9961 40.6608 16.7442 40.6154 16.5095 40.5272C16.2858 40.4427 16.0858 40.3148 15.9237 40.1526C15.7555 39.9832 15.6258 39.7859 15.5418 39.5719C15.4451 39.3288 15.3979 39.0721 15.4027 38.814C15.3988 38.556 15.4459 38.2994 15.5418 38.0562C15.6246 37.8416 15.7544 37.6442 15.9237 37.4754C16.0848 37.3116 16.2851 37.1826 16.5095 37.0979C16.7442 37.0098 16.9961 36.9644 17.2506 36.9644C17.5051 36.9644 17.757 37.0098 17.9917 37.0979C18.2161 37.1826 18.4164 37.3116 18.5775 37.4754C18.7469 37.6446 18.8777 37.8419 18.9626 38.0562C19.0558 38.3 19.1018 38.5563 19.0985 38.814ZM18.3639 38.814C18.3806 38.4812 18.2748 38.1526 18.0629 37.879C17.9649 37.7715 17.8414 37.6849 17.7015 37.6254C17.5616 37.5659 17.4087 37.535 17.2538 37.535C17.099 37.535 16.9461 37.5659 16.8062 37.6254C16.6662 37.6849 16.5428 37.7715 16.4448 37.879C16.2481 38.1638 16.144 38.4921 16.144 38.8271C16.144 39.1621 16.2481 39.4904 16.4448 39.7751C16.5428 39.8827 16.6662 39.9693 16.8062 40.0288C16.9461 40.0883 17.099 40.1192 17.2538 40.1192C17.4087 40.1192 17.5616 40.0883 17.7015 40.0288C17.8414 39.9693 17.9649 39.8827 18.0629 39.7751C18.2813 39.4945 18.3874 39.1557 18.3639 38.814Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M21.298 35.1226C21.4819 35.1207 21.6656 35.135 21.8466 35.1653C21.96 35.1819 22.0713 35.2085 22.1791 35.2446L22.0461 35.8152C21.9626 35.778 21.8744 35.7503 21.7834 35.7328C21.645 35.7036 21.5032 35.6903 21.3612 35.6932C21.2301 35.6801 21.0976 35.6964 20.9748 35.7405C20.8521 35.7845 20.7428 35.8552 20.6562 35.9464C20.5103 36.1472 20.4391 36.3859 20.4534 36.6268V36.9503H22.0062V37.5178H20.4434V40.6606H19.7186V36.6024C19.69 36.2032 19.8266 35.8088 20.1009 35.4979C20.2598 35.3606 20.4487 35.2561 20.6552 35.1914C20.8617 35.1266 21.0808 35.1032 21.298 35.1226Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M26.0431 35.7377C26.6385 35.7022 27.2313 35.8377 27.7412 36.1259C27.9382 36.2574 28.0961 36.4331 28.2002 36.6368C28.3043 36.8405 28.3511 37.0654 28.3363 37.2905C28.3436 37.5396 28.2882 37.7869 28.1746 38.0127C28.0662 38.2102 27.9046 38.3781 27.7056 38.5002C27.4765 38.6323 27.2232 38.7241 26.9585 38.771C26.6323 38.8292 26.3008 38.8575 25.9687 38.8552H25.3865V40.6607H24.6426V35.8461C24.8701 35.7971 25.1016 35.7658 25.3348 35.7528C25.6129 35.7588 25.8329 35.7377 26.0431 35.7377ZM26.1046 36.3396C25.7811 36.3396 25.5515 36.3396 25.3865 36.3606V38.2745H25.9364C26.1637 38.2762 26.3908 38.2611 26.6156 38.2293C26.7942 38.2061 26.9672 38.1542 27.1267 38.0759C27.264 38.0075 27.3766 37.9028 27.4501 37.775C27.5326 37.6254 27.5717 37.4584 27.5633 37.2905C27.5697 37.1291 27.5295 36.9691 27.4469 36.8271C27.37 36.7016 27.2582 36.5977 27.1234 36.5261C26.979 36.45 26.8214 36.3981 26.6577 36.3727C26.4747 36.3457 26.2898 36.3326 26.1046 36.3336V36.3396Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M28.9551 40.6606V35.229L29.6382 35.116V37.0161C29.7697 36.9658 29.9062 36.929 30.0455 36.9061C30.1859 36.8794 30.3287 36.8661 30.4717 36.8664C30.7294 36.859 30.9861 36.9016 31.2269 36.9917C31.415 37.0652 31.5803 37.1852 31.7063 37.3399C31.8328 37.4985 31.9197 37.6837 31.9601 37.8806C32.0122 38.1082 32.0375 38.3409 32.0353 38.5741V40.6606H31.3522V38.7177C31.3553 38.5192 31.3396 38.3208 31.3052 38.125C31.2819 37.9844 31.2286 37.8501 31.1485 37.731C31.0749 37.6315 30.9723 37.5559 30.854 37.5141C30.708 37.465 30.554 37.4422 30.3996 37.4469C30.3245 37.4425 30.2491 37.4425 30.174 37.4469L29.9578 37.5141C29.8889 37.5141 29.8231 37.5385 29.7698 37.5538C29.7283 37.5623 29.6875 37.5735 29.6476 37.5874V40.6606H28.9551Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M36.3485 38.814C36.3526 39.072 36.3066 39.3286 36.2125 39.5723C36.1269 39.7858 35.9972 39.983 35.8302 40.1534C35.6674 40.315 35.4674 40.4429 35.244 40.5282C34.7679 40.705 34.2332 40.705 33.7572 40.5282C33.5337 40.4429 33.3337 40.315 33.1709 40.1534C33.0025 39.9839 32.8727 39.7865 32.7886 39.5723C32.6945 39.3286 32.6485 39.072 32.6526 38.814C32.6493 38.5561 32.6953 38.2997 32.7886 38.0557C32.8715 37.8411 33.0015 37.6435 33.1709 37.4746C33.3327 37.3114 33.533 37.1823 33.7572 37.0969C34.2332 36.9202 34.7679 36.9202 35.244 37.0969C35.4681 37.1823 35.6684 37.3114 35.8302 37.4746C35.9986 37.6441 36.1285 37.8415 36.2125 38.0557C36.3058 38.2997 36.3518 38.5561 36.3485 38.814ZM35.6132 38.814C35.633 38.4766 35.5283 38.1426 35.3152 37.864C35.2168 37.7568 35.0931 37.6706 34.9531 37.6113C34.8131 37.5521 34.6602 37.5214 34.5054 37.5214C34.3506 37.5214 34.1978 37.5521 34.0577 37.6113C33.9177 37.6706 33.7941 37.7568 33.6956 37.864C33.5006 38.1495 33.3974 38.4778 33.3974 38.8126C33.3974 39.1474 33.5006 39.4757 33.6956 39.7612C33.7941 39.8683 33.9177 39.9546 34.0577 40.0138C34.1978 40.073 34.3506 40.1037 34.5054 40.1037C34.6602 40.1037 34.8131 40.073 34.9531 40.0138C35.0931 39.9546 35.2168 39.8683 35.3152 39.7612C35.5278 39.4834 35.6326 39.1505 35.6132 38.814Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M37.5807 38.8252C37.5739 38.5465 37.6201 38.2691 37.7168 38.0077C37.7958 37.7926 37.9168 37.5955 38.0729 37.4279C38.2177 37.277 38.3935 37.1593 38.5881 37.0829C38.7776 37.0061 38.9801 36.9667 39.1845 36.967C39.3879 36.9543 39.5916 36.9876 39.7805 37.0644C39.9694 37.1411 40.1387 37.2594 40.2758 37.4105C40.5557 37.8005 40.6899 38.2766 40.655 38.7556V38.86C40.6582 38.8995 40.6582 38.9393 40.655 38.9788H38.2465C38.2502 39.2862 38.3631 39.5822 38.565 39.8137C38.6825 39.9186 38.8201 39.9984 38.9694 40.0483C39.1187 40.0981 39.2766 40.1169 39.4334 40.1036C39.6213 40.1067 39.8089 40.0873 39.9921 40.0456C40.1093 40.0191 40.2237 39.9812 40.3337 39.9326L40.4205 40.4631C40.2919 40.5214 40.1569 40.5642 40.0182 40.5906C39.8036 40.6397 39.584 40.663 39.3639 40.6602C39.093 40.6666 38.8234 40.6194 38.5708 40.5211C38.3599 40.437 38.1709 40.3062 38.0179 40.1384C37.8709 39.9701 37.7614 39.7725 37.6965 39.5586C37.6215 39.3213 37.5824 39.0741 37.5807 38.8252ZM39.9979 38.4802C40.0126 38.2282 39.9366 37.9793 39.7837 37.7787C39.712 37.6879 39.6197 37.6155 39.5145 37.5677C39.4092 37.5198 39.2941 37.4977 39.1787 37.5033C39.0465 37.501 38.9156 37.5298 38.7966 37.5874C38.6859 37.64 38.5874 37.715 38.5071 37.8077C38.4274 37.8993 38.3664 38.0057 38.3276 38.1208C38.2844 38.2367 38.2553 38.3574 38.2408 38.4802H39.9979Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M41.8926 37.1467C42.0491 37.108 42.2588 37.0693 42.5186 37.0277C42.8137 36.9834 43.1121 36.9625 43.4108 36.9652C43.6683 36.9579 43.9247 36.9994 44.1652 37.0872C44.3531 37.1587 44.5182 37.2756 44.6441 37.4264C44.7705 37.5809 44.8573 37.7612 44.8977 37.953C44.9498 38.1747 44.975 38.4014 44.9728 38.6285V40.6608H44.2967V38.7683C44.2994 38.5769 44.2837 38.3857 44.2497 38.197C44.2265 38.0601 44.1731 37.9293 44.0932 37.8132C44.0196 37.7163 43.9171 37.6427 43.7989 37.6019C43.6531 37.5541 43.4993 37.5319 43.345 37.5365C43.273 37.5365 43.1979 37.5365 43.1197 37.5365C43.0414 37.5365 42.9663 37.5365 42.8943 37.5543L42.7033 37.5781L42.5844 37.599V40.6608H41.902L41.8926 37.1467Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M46.2036 36.037C46.0407 36.0393 45.8832 35.9941 45.7654 35.9115C45.6524 35.8197 45.5898 35.7004 45.5898 35.5766C45.5898 35.4528 45.6524 35.3335 45.7654 35.2417C45.8832 35.1591 46.0407 35.1139 46.2036 35.1162C46.2843 35.1144 46.3648 35.1245 46.4401 35.1461C46.5153 35.1677 46.5839 35.2002 46.6417 35.2417C46.7575 35.3325 46.822 35.4522 46.822 35.5766C46.822 35.701 46.7575 35.8207 46.6417 35.9115C46.5839 35.953 46.5153 35.9855 46.4401 36.0071C46.3648 36.0287 46.2843 36.0388 46.2036 36.037ZM46.6855 40.6606H45.7304V36.7454H46.6855V40.6606Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M50.3268 40.6608C50.2708 40.5544 50.1983 40.4389 50.1192 40.3112C50.0402 40.1835 49.9446 40.0528 49.8491 39.916L49.5493 39.5148C49.4439 39.381 49.345 39.2564 49.2462 39.1378C49.1506 39.2564 49.0485 39.3841 48.9464 39.5178C48.8443 39.6516 48.7454 39.7884 48.6499 39.9251C48.5543 40.0619 48.4654 40.1896 48.383 40.3173C48.3007 40.445 48.2315 40.5605 48.1755 40.6608H47.4375C47.633 40.3193 47.8531 39.9902 48.0964 39.6759C48.3468 39.3415 48.5939 39.0223 48.841 38.7183L47.5232 36.9644H48.3303L49.2857 38.2563L50.2477 36.9644H51.012L49.7173 38.6758C49.9644 38.9798 50.2181 39.3111 50.475 39.6546C50.7209 39.9747 50.9411 40.311 51.1339 40.6608H50.3268Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M56.5314 38.5537H57.2949V41.0403C57.2299 41.06 57.1391 41.0829 57.0225 41.1092C56.9048 41.1354 56.7669 41.1584 56.6122 41.1846C56.4574 41.2109 56.2758 41.2338 56.1077 41.2502C55.9222 41.2689 55.7358 41.2776 55.5493 41.2765C55.1907 41.282 54.8343 41.2196 54.5 41.0928C54.193 40.9747 53.9171 40.7908 53.6927 40.5548C53.4573 40.3054 53.2785 40.0102 53.1681 39.6888C53.0378 39.3083 52.9752 38.9089 52.9831 38.5078C52.9761 38.1038 53.0469 37.7021 53.1916 37.3236C53.3169 36.9989 53.5079 36.7021 53.7533 36.451C53.9892 36.2185 54.2721 36.0364 54.584 35.9162C54.9052 35.7924 55.2478 35.7301 55.5931 35.7325C55.8126 35.7296 56.032 35.7449 56.2489 35.7785C56.4148 35.8028 56.5788 35.8379 56.74 35.8834C56.8551 35.9167 56.9676 35.9584 57.0763 36.0081C57.1365 36.0352 57.1949 36.0659 57.2512 36.0999L57.009 36.72C56.8258 36.6104 56.626 36.5296 56.4171 36.4805C56.1659 36.4149 55.9069 36.3818 55.6469 36.3821C55.3857 36.3787 55.1267 36.429 54.8867 36.5297C54.6614 36.6226 54.4619 36.7665 54.3049 36.9496C54.138 37.1426 54.0112 37.3654 53.9316 37.6057C53.8376 37.8918 53.7922 38.191 53.797 38.4914C53.793 38.7839 53.8315 39.0755 53.9114 39.3574C53.9761 39.6013 54.0905 39.83 54.2477 40.0299C54.4018 40.2164 54.5989 40.3647 54.8228 40.4629C55.0761 40.5706 55.3504 40.6232 55.6267 40.6171C55.8146 40.6188 56.0023 40.6067 56.1884 40.581C56.3021 40.5677 56.4145 40.5458 56.5247 40.5154L56.5314 38.5537Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M59.503 36.9644H59.7352L59.9887 36.9942L60.2173 37.0269C60.2887 37.0269 60.3387 37.0508 60.3744 37.0597L60.2387 37.6222C60.1389 37.5961 60.0376 37.5742 59.9352 37.5567C59.7549 37.5291 59.5719 37.5161 59.3887 37.518C59.2435 37.5189 59.0987 37.5319 58.9566 37.5567L58.678 37.6103V40.6608H57.9102V37.1936C58.1345 37.1291 58.3635 37.0764 58.5959 37.0359C58.8946 36.9866 59.1985 36.9627 59.503 36.9644Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M64.0712 38.814C64.0753 39.0718 64.0293 39.3283 63.9351 39.5719C63.8511 39.7859 63.7213 39.9832 63.5529 40.1526C63.3907 40.3148 63.1905 40.4427 62.9666 40.5272C62.7317 40.6154 62.4795 40.6608 62.2248 40.6608C61.9701 40.6608 61.718 40.6154 61.4831 40.5272C61.2582 40.4427 61.0569 40.3148 60.8935 40.1526C60.7266 39.9823 60.5969 39.7853 60.5113 39.5719C60.4172 39.3283 60.3711 39.0718 60.3753 38.814C60.372 38.5563 60.418 38.3 60.5113 38.0562C60.5953 37.8421 60.7251 37.6448 60.8935 37.4754C61.0557 37.3113 61.2573 37.1822 61.4831 37.0979C61.718 37.0098 61.9701 36.9644 62.2248 36.9644C62.4795 36.9644 62.7317 37.0098 62.9666 37.0979C63.1912 37.1826 63.3916 37.3116 63.5529 37.4754C63.7223 37.6442 63.8523 37.8416 63.9351 38.0562C64.0284 38.3 64.0745 38.5563 64.0712 38.814ZM63.3359 38.814C63.3572 38.4766 63.2523 38.1425 63.0379 37.8645C62.9394 37.7574 62.8157 37.6712 62.6757 37.612C62.5357 37.5528 62.3829 37.5222 62.2281 37.5222C62.0733 37.5222 61.9204 37.5528 61.7804 37.612C61.6404 37.6712 61.5167 37.7574 61.4183 37.8645C61.2233 38.1498 61.1201 38.4779 61.1201 38.8126C61.1201 39.1472 61.2233 39.4753 61.4183 39.7606C61.5167 39.8677 61.6404 39.9539 61.7804 40.0131C61.9204 40.0723 62.0733 40.103 62.2281 40.103C62.3829 40.103 62.5357 40.0723 62.6757 40.0131C62.8157 39.9539 62.9394 39.8677 63.0379 39.7606C63.2473 39.4821 63.3475 39.1492 63.3229 38.814H63.3359Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M68.3768 40.4782C68.22 40.5169 68.0131 40.5556 67.7497 40.5973C67.4552 40.6419 67.1573 40.6628 66.8591 40.6599C66.6063 40.6676 66.3544 40.6261 66.1191 40.5378C65.9295 40.4654 65.7631 40.3474 65.6362 40.1953C65.5103 40.0392 65.4206 39.8596 65.3728 39.6682C65.3204 39.4515 65.2973 39.2294 65.3038 39.0072V36.9763H65.9843V38.8702C65.9567 39.1995 66.0331 39.5292 66.2038 39.8171C66.2927 39.9205 66.4069 40.0017 66.5364 40.0536C66.666 40.1055 66.8069 40.1266 66.9469 40.1149H67.1727C67.2511 40.1149 67.3232 40.1149 67.3922 40.097L67.5803 40.0762C67.621 40.0732 67.661 40.0652 67.6995 40.0524V36.9644H68.3831L68.3768 40.4782Z"
            fill={theme.colors.blue__primary__70}
        />
        <Path
            d="M73.3115 39.0724C73.3143 39.3585 73.2724 39.6433 73.1873 39.9175C73.1103 40.1594 72.9849 40.3845 72.818 40.5803C72.6568 40.7661 72.4538 40.9145 72.2244 41.0144C71.9724 41.1213 71.6992 41.1744 71.4237 41.1702C71.2059 41.1729 70.9891 41.1415 70.7818 41.0774C70.6364 41.0321 70.4964 40.9721 70.3642 40.8984V42.509H69.6152V37.1735C69.7912 37.1338 70.0156 37.0874 70.2813 37.0377C70.5854 36.9853 70.8939 36.9609 71.2028 36.9647C71.5007 36.9612 71.7968 37.0107 72.076 37.1106C72.3257 37.2037 72.5517 37.3472 72.7386 37.5314C72.9275 37.7209 73.0722 37.9469 73.1631 38.1942C73.2642 38.4767 73.3143 38.7737 73.3115 39.0724ZM72.5281 39.0724C72.5554 38.6801 72.4258 38.2925 72.1657 37.9888C72.0426 37.8614 71.892 37.7614 71.7246 37.6955C71.5571 37.6297 71.3767 37.5997 71.1959 37.6077C71.0196 37.6048 70.8432 37.6125 70.6679 37.6308C70.5656 37.6424 70.4642 37.6601 70.3642 37.6839V40.2257C70.485 40.3137 70.6187 40.3841 70.7611 40.4345C70.9406 40.505 71.1331 40.5399 71.3271 40.5372C71.5178 40.5424 71.7072 40.5038 71.8793 40.4245C72.0279 40.3511 72.1558 40.2442 72.252 40.113C72.3555 39.9744 72.4273 39.8163 72.4625 39.6491C72.5022 39.4593 72.5184 39.2658 72.5108 39.0724H72.5281Z"
            fill={theme.colors.blue__primary__70}
        />
    </Svg>
);

StandardLifeGroupLogo.displayName = 'StandardLifeGroupLogo';
