import { PensionFormValues, TransferAmountOptions } from '~/types';

export const initialPension: PensionFormValues = {
    policyNumber: '',
    provider: '',
    value: '',
    moneyWithdrawn: '',
    transferAmountOption: '',
    declaration: false,
};

export const pensionProviderListItems = [
    { label: 'Abbey Life', value: 'Abbey Life' },
    { label: 'Aegon', value: 'Aegon' },
    { label: 'AJ Bell', value: 'AJ Bell' },
    { label: 'Aon', value: 'Aon' },
    { label: 'Aviva', value: 'Aviva' },
    { label: 'B & CE', value: 'B & CE' },
    { label: 'Blackrock', value: 'Blackrock' },
    { label: 'Capita', value: 'Capita' },
    { label: 'Clerical Medical', value: 'Clerical Medical' },
    { label: 'Countrywide Assured', value: 'Countrywide Assured' },
    { label: 'Curtis Bank', value: 'Curtis Bank' },
    { label: 'Equitable Life', value: 'Equitable Life' },
    { label: 'Fidelity', value: 'Fidelity' },
    { label: 'Friends Life', value: 'Friends Life' },
    { label: 'Hargreaves Landsdown', value: 'Hargreaves Landsdown' },
    { label: 'II SIPP (interactive Investor)', value: 'II SIPP (interactive Investor)' },
    { label: 'JLT', value: 'JLT' },
    { label: 'Legal & General', value: 'Legal & General' },
    { label: 'LV=', value: 'LV=' },
    { label: 'Mercer', value: 'Mercer' },
    { label: 'Moneybox Personal Pension', value: 'Moneybox Personal Pension' },
    { label: 'Moneyfarm', value: 'Moneyfarm' },
    { label: 'Nest', value: 'Nest' },
    { label: 'NFU Mutual', value: 'NFU Mutual' },
    { label: 'Now Pensions', value: 'Now Pensions' },
    { label: 'Nutmeg', value: 'Nutmeg' },
    { label: 'Old Mutual Wealth', value: 'Old Mutual Wealth' },
    { label: 'PensionBee', value: 'PensionBee' },
    { label: 'Peoples Pension', value: 'Peoples Pension' },
    { label: 'Phoenix Life', value: 'Phoenix Life' },
    { label: 'Prudential', value: 'Prudential' },
    { label: 'Reassure', value: 'Reassure' },
    { label: 'Royal London', value: 'Royal London' },
    { label: 'Scottish Friendly', value: 'Scottish Friendly' },
    { label: 'Scottish Widows', value: 'Scottish Widows' },
    { label: 'Smart Pensions', value: 'Smart Pensions' },
    { label: 'Standard Life', value: 'Standard Life' },
    { label: 'Sun Life Financial of Canada', value: 'Sun Life Financial of Canada' },
    { label: 'Other provider', value: 'Other provider' },
];

export const transferAmountOptions = [
    {
        label: 'My full pension value',
        value: TransferAmountOptions.MAXIMUM_PENSION_VALUE,
    },
    {
        label: 'Half of my pension value',
        value: TransferAmountOptions.HALF_PENSION_VALUE,
    },
    {
        label: 'Other amount',
        value: TransferAmountOptions.SPECIFY,
    },
];
