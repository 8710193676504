import { StyleSheet } from '@react-pdf/renderer';
import { theme } from '@slal/ui/theme';
import './fonts';

const pagePadding = 30;
const pagePaddingBottom = 40;

const baseTextStyles = {
    color: theme.colors.neutral__dark__20,
    fontFamily: 'Arial',
    fontSize: 12,
};

export const styles = StyleSheet.create({
    page: {
        paddingTop: pagePadding,
        paddingBottom: pagePaddingBottom,
        paddingLeft: pagePadding,
        paddingRight: pagePadding,
        backgroundColor: '#f2f2f2',
    },
    documentTitle: {
        ...baseTextStyles,
        color: theme.colors.blue__primary__70,
        fontFamily: 'Ubuntu',
        fontSize: 30,
        fontWeight: 'bold',
        marginTop: 25,
        marginBottom: 10,
    },
    smallHeading: {
        ...baseTextStyles,
        fontFamily: 'Ubuntu',
        marginTop: 30,
        fontSize: 16,
        marginBottom: 20,
        textAlign: 'left',
        color: theme.colors.neutral__dark__10,
    },
    text: {
        ...baseTextStyles,
        marginBottom: 5,
    },
    logo: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    dateText: {
        ...baseTextStyles,
        fontFamily: 'Ubuntu',
        fontSize: 16,
        color: theme.colors.neutral__dark__10,
    },
    date: {
        textAlign: 'right',
        alignItems: 'flex-end',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    footer: {
        position: `absolute`,
        left: pagePadding,
        right: 0,
        bottom: 20,
        flexDirection: 'row',
        gap: 5,
    },
    footerText: {
        ...baseTextStyles,
        fontSize: 10,
        fontWeight: 'bold',
    },
    pagination: {
        ...baseTextStyles,
        color: theme.colors.blue__primary__70,
        fontSize: 10,
        fontWeight: 'bold',
    },
    zClause: {
        marginTop: 20,
        marginBottom: 20,
    },
    smallprint: {
        ...baseTextStyles,
        fontSize: 8,
        marginBottom: 3,
    },
});
