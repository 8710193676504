import { styled } from '@slal/ui';
import { breakpointDown, breakpointUp } from '@slal/ui/helpers';
import { P, Text } from '@slal/ui/typography';
import { Card, DescriptionList } from '~/components';

export const StyledPhoneNumber = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes[6]};
`;

export const StyledOpeningHours = styled.div`
    margin: ${({ theme }) => theme.space[4]} 0 0;
    font-size: ${({ theme }) => theme.fontSizes[6]};
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
    p {
        margin: 0;
        font-size: ${({ theme }) => theme.fontSizes[3]};
    }
`;

export const StyledApplicationButtonsWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
`;

export const EstimatedCompletionTime = styled(Text)`
    display: flex;
    color: ${({ theme }) => theme.colors.neutral__dark__20};
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.space[4]};
    margin-left: ${({ theme }) => theme.space[5]};
`;

export const StyledHelpPhoneNumber = styled(P)`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
    padding-top: ${({ theme }) => theme.space[4]};
    font-weight: ${({ theme }) => theme.fonts.headings.fontWeights.regular};
`;

export const ReviewCard = styled(Card)`
    border-color: ${({ theme }) => theme.colors.neutral__light__30};
`;

export const StyledHr = styled.hr`
    border-top: 1px solid ${({ theme }) => theme.colors.neutral__light__30};
    margin: ${({ theme }) => theme.space[5]} 0;
`;

export const StyledDescriptionList = styled(DescriptionList)`
    padding-top: 6px;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${({ theme }) => theme.space[4]};
    overflow: wrap;

    ${breakpointDown.xs`
        &:not(.no-hr) dd::after { 
            content: '';
            display: block;
            border-top: 1px solid ${({ theme }) => theme.colors.neutral__light__30};
            margin: ${({ theme }) => theme.space[5]} 0 0 0;
            width: 100%;
        }

        &:not(.no-hr) dd:last-of-type::after {
            content: none;
        }
    `}

    ${breakpointUp.sm`
        grid-template-columns: 50% 50%;
        row-gap: ${({ theme }) => theme.space[4]}; 

        dt {
            grid-column: 1 / 2;
            grid-row: span 2;
            align-self: start;
            padding-right: ${({ theme }) => theme.space[5]};
        }

        dd {
            grid-column: 2 / 3;
        }

        &:not(.no-hr) dd::after {
            display: none;
        }
    `}
    
    dt {
        margin: 0;
    }

    dd {
        color: inherit;
        margin: 0 0 ${({ theme }) => theme.space[4]} 0;
        word-break: break-word;

        &:last-of-type {
            margin: 0;
        }
    }
`;

export const StyledReviewContainer = styled.section``;

export const MultilineValue = styled.div`
    margin-bottom: ${({ theme }) => theme.space[3]};
`;
