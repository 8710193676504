import { Heading } from '@slal/ui/typography';
import { uniqueId } from 'lodash';
import {
    StyledNotificationContent,
    StyledNotificationCard,
    StyledWarningIcon,
    StyledInformationIcon,
    StyledDangerIcon,
    StyledSuccessIcon,
} from './styles';
import { NotificationProps } from './types';

const Icon = ({ level }: NotificationProps) => {
    switch (level) {
        case 'danger':
            return <StyledDangerIcon />;
        case 'success':
            return <StyledSuccessIcon />;
        case 'warning':
            return <StyledWarningIcon />;
        default:
            return <StyledInformationIcon />;
    }
};

export const Notification = ({ heading, children, ...otherProps }: NotificationProps) => {
    const id = uniqueId('notification-');

    const { level } = otherProps;

    return (
        <StyledNotificationCard role="alert" aria-labelledby={id} {...otherProps}>
            <Icon level={level} />
            <StyledNotificationContent>
                {heading && (
                    <Heading id={id} level={5}>
                        {heading}
                    </Heading>
                )}
                {children}
            </StyledNotificationContent>
        </StyledNotificationCard>
    );
};
