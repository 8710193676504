import {
    ApplicationFormKeys,
    ApplicationPensionValues,
    ApplicationPensionValuesInput,
    ApplicationSchema,
    LumpSumOptions,
    LumpSumOptionsFormValues,
    TransferAmountOptions,
} from '~/types';
import { roundCurrency } from './currency';

export const MAXIMUM_TAX_FREE_CASH_RATIO = 0.25;

export const getLumpSumValue = (
    lumpSumOptions: LumpSumOptionsFormValues,
    maximumTaxFreeCash: ApplicationPensionValues['maximumTaxFreeCash'],
    halfTaxFreeCash: ApplicationPensionValues['halfTaxFreeCash']
) => {
    const { option, specifiedAmount } = lumpSumOptions;
    return option === LumpSumOptions.MAXIMUM_TAX_FREE_CASH
        ? maximumTaxFreeCash
        : option === LumpSumOptions.HALF_TAX_FREE_CASH
          ? halfTaxFreeCash
          : specifiedAmount
            ? parseFloat(specifiedAmount?.toString())
            : 0;
};

export const getPensionTransferValues = (
    pension: ApplicationSchema[ApplicationFormKeys.PENSION_TRANSFER]
): { total: ApplicationPensionValues['total']; transferValue: ApplicationPensionValues['transferValue'] } => {
    const { value, transferAmountOption, specifiedTransferAmount } = pension;
    const total = Number(value) || 0;
    const transferValue =
        transferAmountOption === TransferAmountOptions.MAXIMUM_PENSION_VALUE
            ? total
            : transferAmountOption === TransferAmountOptions.HALF_PENSION_VALUE
              ? total / 2 || 0
              : specifiedTransferAmount
                ? parseFloat(specifiedTransferAmount?.toString())
                : 0;
    return {
        total,
        transferValue,
    };
};

export const getPensionValues = (application: ApplicationPensionValuesInput): ApplicationPensionValues => {
    let maximumRegularIncome: number = 0;

    const { pension, lumpSumOptions, regularIncome, withdrawal } = application;

    if (!pension)
        return {
            halfTaxFreeCash: 0,
            maximumRegularIncome,
            maximumLumpSum: 0,
            maximumTaxFreeCash: 0,
            maximumTaxFreeCashTaken: false,
            transferValue: 0,
            total: 0,
        };

    const { total, transferValue } = getPensionTransferValues(pension);

    const halfTaxFreeCash = (transferValue / 2) * MAXIMUM_TAX_FREE_CASH_RATIO || 0;
    const maximumLumpSum = transferValue - 1 || 0;
    const maximumTaxFreeCash = transferValue * MAXIMUM_TAX_FREE_CASH_RATIO || 0;
    maximumRegularIncome = transferValue / 12 || 0;

    if (!lumpSumOptions) {
        return {
            halfTaxFreeCash: roundCurrency(halfTaxFreeCash),
            maximumRegularIncome: roundCurrency(maximumRegularIncome),
            maximumLumpSum: roundCurrency(maximumLumpSum),
            maximumTaxFreeCash: roundCurrency(maximumTaxFreeCash),
            maximumTaxFreeCashTaken: false,
            policyNumber: pension.policyNumber,
            total: roundCurrency(total),
            transferValue: roundCurrency(transferValue),
        };
    }

    const lumpSum = getLumpSumValue(lumpSumOptions, maximumTaxFreeCash, halfTaxFreeCash);

    maximumRegularIncome = roundCurrency((transferValue - lumpSum) / 12) || 0;
    const drawdownPotValue = lumpSum * 3;
    const savingsPotValue = roundCurrency(transferValue - lumpSum - drawdownPotValue) || undefined;

    const result = {
        halfTaxFreeCash: roundCurrency(halfTaxFreeCash),
        maximumRegularIncome,
        maximumLumpSum: roundCurrency(maximumLumpSum),
        maximumTaxFreeCash: roundCurrency(maximumTaxFreeCash),
        maximumTaxFreeCashTaken: lumpSum >= maximumTaxFreeCash,
        policyNumber: pension.policyNumber,
        pots: {
            drawdown: roundCurrency(drawdownPotValue),
            savings: savingsPotValue,
        },
        remainderAfterLumpSum: roundCurrency(transferValue - lumpSum),
        total: roundCurrency(total),
        transferValue: roundCurrency(transferValue),
        withdrawals: {
            lumpSumOption: lumpSumOptions.option,
            lumpSum: roundCurrency(lumpSum),
        },
    };

    if (withdrawal?.option === 'Lump sum' || !regularIncome) return result;

    return {
        ...result,
        withdrawals: {
            ...result.withdrawals,
            regularIncome: regularIncome.amount!
                ? roundCurrency(parseFloat(regularIncome.amount!.toString()))
                : undefined,
            regularIncomePaymentDay: regularIncome.day! ? parseFloat(regularIncome.day!.toString()) : undefined,
        },
    };
};

export const formatEstimatedTimescales = (startDays: number, endDays: number): string => {
    const today = new Date();

    const startDate = new Date(today);
    startDate.setDate(today.getDate() + startDays);

    const endDate = new Date(today);
    endDate.setDate(today.getDate() + endDays);

    const options: Intl.DateTimeFormatOptions = { month: 'long' };

    const startFormatted = `${startDate.getDate()} ${startDate.toLocaleDateString(undefined, options)}`;
    const endFormatted = `${endDate.getDate()} ${endDate.toLocaleDateString(undefined, options)}`;

    return `${startFormatted} - ${endFormatted}`;
};
