import { styled } from '@slal/ui';
import { Field } from '@slal/ui/react-hook-form/fields';
import { Article, Text } from '@slal/ui/typography';
import { StyledForm } from '~/components';

export const StyledField = styled(Field)`
    div {
        display: flex;
        align-items: flex-start;
    }

    input {
        margin-top: 3px;
    }

    margin-bottom: ${({ theme }) => theme.space[5]};
`;

export const StyledDeclarationForm = styled(StyledForm)`
    margin-top: ${({ theme }) => theme.space[5]};
`;

export const DeclarationFieldLabel = styled(Text)`
    line-height: ${({ theme }) => theme.lineHeights.copy};
`;

export const StyledArticle = styled(Article)`
    margin-bottom: ${({ theme }) => theme.space[7]};
`;
