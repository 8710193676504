import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';

export const SpinnerWrapper = styled.div`
    ${breakpointUp.md`
        padding: 150px 0;
    `}

    ${breakpointUp.lg`
        padding: 200px 0;
    `}
    padding: 100px 0;
`;
