import { styled } from '@slal/ui';
import { Text } from '@slal/ui/typography';

export const StyledLi = styled.li`
    margin-bottom: ${({ theme }) => theme.space[4]};
    color: ${({ theme }) => theme.colors.blue__secondary__80};
`;

export const StyledLiText = styled(Text)`
    color: ${({ theme }) => theme.colors.primaryText};
`;

StyledLiText.defaultProps = {
    tag: 'span',
};

export const StyledUl = styled.ul``;

export const StyledOl = styled.ol``;
