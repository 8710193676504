import './init';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';

if (!import.meta.env.VITE_AWS_DEFAULT_REGION || !import.meta.env.VITE_GRAPHQL_API_URL) {
    const errorMessage = 'Required config unavailable';
    console.error(errorMessage, {
        apiUrl: import.meta.env.VITE_GRAPHQL_API_URL,
        region: import.meta.env.VITE_AWS_DEFAULT_REGION,
    });
    throw Error(errorMessage);
}

const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: async () => (await fetchAuthSession()).credentials!,
};

const config = {
    auth,
    region: import.meta.env.VITE_AWS_DEFAULT_REGION,
    url: import.meta.env.VITE_GRAPHQL_API_URL,
};

const httpLink = new HttpLink({ uri: config.url });

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map(data => {
        return data;
    });
});

const link = ApolloLink.from([cleanTypeName, createAuthLink(config), httpLink]);

export const cache = new InMemoryCache();

export const client = new ApolloClient({
    link,
    cache,
});
