import { styled } from '@slal/ui';
import { Field } from '@slal/ui/react-hook-form/fields';
import { P } from '@slal/ui/typography';

export const SavingsInvestmentOptionsField = styled(Field)`
    div {
        margin-top: ${({ theme: { space } }) => space[3]};
    }
`;

export const SavingsPotHeadline = styled(P)`
    margin-bottom: ${({ theme: { space } }) => space[7]};
`;
