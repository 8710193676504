import { Col, Row } from '@slal/ui/layout';
import { Content, ContentProps } from '~/components';
import { StyledContainer } from './styles';
import { ReactNode } from 'react';

export const ApplicationFormLayout = ({
    children,
    sideBar,
    title,
    ...otherProps
}: ContentProps & { sideBar?: ReactNode }) => (
    <StyledContainer>
        <Row>
            <Col md={7} lg={8} xl={9}>
                <Content title={title} {...otherProps}>
                    {children}
                </Content>
            </Col>
            <Col md={5} lg={4} xl={3}>
                {sideBar && sideBar}
            </Col>
        </Row>
    </StyledContainer>
);
