import { get, post } from 'aws-amplify/api';
import { useState, useCallback } from 'react';
import {
    DocumentType,
    GetInput,
    PostInput,
    UseRestApiGetRequestResponse,
    UseRestApiPostRequestResponse,
} from './types';

export const useRestApiGetRequest = (config: GetInput): UseRestApiGetRequestResponse => {
    const [data, setData] = useState<DocumentType | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<UseRestApiGetRequestResponse['error'] | undefined>();

    const sendRequest = useCallback(async () => {
        try {
            if (!loading) setLoading(true);
            if (error) setError(undefined);
            const { body } = await get({ ...config, apiName: import.meta.env.VITE_REST_API_NAME }).response;
            const data = await body.json();
            setData(data);
        } catch (error) {
            setError(error as UseRestApiGetRequestResponse['error']);
        } finally {
            setLoading(false);
        }
    }, [config, error, loading, setData, setError, setLoading]);

    return { data, error: !!error, loading, sendRequest };
};

export const useRestApiPostRequest = (config: PostInput): UseRestApiPostRequestResponse => {
    const [data, setData] = useState<DocumentType | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<UseRestApiPostRequestResponse['error'] | undefined>();

    const sendRequest = useCallback(
        async (data: DocumentType | FormData) => {
            try {
                !loading && setLoading(true);
                !!error && setError(undefined);
                const { body } = await post({
                    ...config,
                    apiName: import.meta.env.VITE_REST_API_NAME,
                    options: { body: data },
                }).response;
                const response = await body.json();
                setData(response);
            } catch (error) {
                setError(error as UseRestApiPostRequestResponse['error']);
            } finally {
                setLoading(false);
            }
        },
        [config, error, loading, setData, setError, setLoading]
    );

    return { data, error: !!error, loading, sendRequest };
};
