import { styled } from '@slal/ui';
import { breakpointDown } from '@slal/ui/helpers';
import { Field } from '@slal/ui/react-hook-form/fields';
import { Li, P, Text, Ul } from '@slal/ui/typography';
import { DocumentLink, LinkButton, LoadingSpinner, StyledForm } from '~/components';
import { StyledReviewContainer } from '../../styles';

export const StyledPropertyWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    ${breakpointDown.sm`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const ValueWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1px; /* Space between related lines */
`;

export const Label = styled(P)`
    flex: 1;
    min-width: 150px;
`;

export const StyledFundWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.space[5]};
    padding-bottom: ${({ theme }) => theme.space[5]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral__dark__60};
    a {
        padding-top: ${({ theme }) => theme.space[4]};
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
`;

export const ReviewWithdrawalWrapper = styled.div``;

export const StyledDeclarationsLabel = styled(Text)`
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    line-height: ${({ theme }) => theme.lineHeights.copy};
`;

export const StyledField = styled(Field)`
    div {
        display: flex;
        align-items: flex-start;
    }

    input {
        margin-top: 3px;
    }

    margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const StyledDeclarationForm = styled(StyledForm)`
    margin-top: -${({ theme }) => theme.space[5]};
`;

export const ReviewWithdrawalContainer = styled(StyledReviewContainer)`
    margin-bottom: ${({ theme }) => theme.space[6]};
`;

export const StyledDocuments = styled(Ul)`
    list-style-type: none;
    padding-left: ${({ theme: { space } }) => space[5]};
    margin-top: -${({ theme: { space } }) => space[5]};

    li {
        margin-bottom: ${({ theme: { space } }) => space[6]};
    }
`;

export const StyledDeclarationLink = styled(LinkButton)``;

export const StyledLi = styled(Li)`
    margin-bottom: ${({ theme }) => theme.space[5]};
`;

export const IllustrationButtonLoadingSpinner = styled(LoadingSpinner)`
    margin-left: ${({ theme }) => theme.space[4]};
`;

export const StyledDocumentLink = styled(DocumentLink)`
    margin-top: ${({ theme }) => theme.space[6]};
`;
