import { styled } from '@slal/ui';
import { Input } from '@slal/ui/forms';
import { FieldSet, TextField as SUITextField } from '@slal/ui/react-hook-form/fields';
import { Article, Text } from '@slal/ui/typography';

export const StyledForm = styled.form``;

export const StyledSubmitButtonWrapper = styled.div`
    display: flex;
    margin-top: ${({ theme }) => theme.space[7]};
    padding-bottom: ${({ theme }) => theme.space[7]};
`;

export const StyledFormIntroduction = styled(Article)`
    margin-bottom: ${({ theme }) => theme.space[5]};
    p {
        margin-top: -${({ theme }) => theme.space[5]};
    }
`;

const getSize = (size?: number) => {
    if (!size) return '100%';
    switch (size) {
        case 4:
            return '80px';
        case 3:
            return '68px';
        case 2:
            return '56px';
        default:
            return '44px';
    }
};

export const StyledInput = styled(Input)`
    width: ${({ size }) => getSize(size)};
    margin-right: ${({ size, theme }) => (size ? theme.space[4] : '0')};
`;

export const StyledDateFieldSet = styled(FieldSet)`
    padding-top: ${({ theme }) => theme.space[2]};
    margin: 0;
    label {
        margin-bottom: ${({ theme }) => theme.space[4]};
        font-size: inherit;
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
`;

export const TextField = styled(SUITextField)`
    width: ${({ size }) => getSize(size)};
`;

export const HeadingLabelWrapper = styled.div``;

export const HeadingLabelTitle = styled(Text)``;

export const HeadingLabelDescription = styled(Text)``;
