import { DefaultValues, FieldValues, useForm, UseFormProps } from '@slal/ui/react-hook-form/core';
import { attempt, get, isError } from 'lodash';
import { createContext, useCallback, useContext, useEffect } from 'react';
import {
    ApplicationBackRouteKeys,
    ApplicationContextProps,
    ApplicationFormKeys,
    ApplicationSchema,
    CashLikeFundDeclarationSchema,
    CompletePersonalInformationSchema,
    DrawdownInvestmentPathwayOption,
    DrawdownInvestmentPathwayOptionSchema,
    InvestmentOptions,
    LumpSumOptionsSchema,
    RegularIncomeSchema,
    SchemeSchema,
} from '~/types';
import { useSelectedInvestmentPathway } from './funds';
import { ApplicationFormSubmitHandler, ApplicationPDFProps, UseApplicationFormReturn } from './types';
import { useAnalytics } from './analytics';

export const APPLICATION_KEY = 'APPLICATION';

export const useApplication = <FormValues>() => {
    const setSession = useCallback((values: object) => {
        const applicationSession = sessionStorage.getItem(APPLICATION_KEY);
        const application: ApplicationSchema = applicationSession ? attempt(JSON.parse, applicationSession) : {};

        if (isError(application)) {
            console.error('Error parsing JSON from session storage');
            return;
        }

        const updatedApplication = { ...application, ...values };

        sessionStorage.setItem(APPLICATION_KEY, JSON.stringify(updatedApplication));
    }, []);

    const getSession = useCallback(
        (formKey: ApplicationFormKeys): (ApplicationSchema & DefaultValues<FormValues> & FormValues) | undefined => {
            const applicationSession = sessionStorage.getItem(APPLICATION_KEY);
            const application: ApplicationSchema = applicationSession ? attempt(JSON.parse, applicationSession) : {};

            if (isError(application)) {
                console.error('Error parsing JSON from session storage');
                return undefined;
            }

            const formValues = get(application, formKey);
            if (!formValues) return undefined;

            return {
                [formKey]: formValues,
            } as (ApplicationSchema & DefaultValues<FormValues> & FormValues) | undefined;
        },
        []
    );

    return {
        setSession,
        getSession,
    };
};

export const useApplicationForm = <FormValues extends FieldValues = FieldValues>(
    options: UseFormProps<FormValues>,
    formKey: ApplicationFormKeys
): UseApplicationFormReturn<FormValues> => {
    const { getSession, setSession } = useApplication<FormValues>();
    const { pushEvent } = useAnalytics();

    useEffect(() => {
        const initialValuesFromStorage = getSession(formKey);

        if (!initialValuesFromStorage) {
            setSession(options.defaultValues!);
        }
    }, []);

    const baseFormOptions: UseFormProps<FormValues> = {
        mode: 'onTouched',
        resetOptions: {
            keepDirty: false,
            keepErrors: false,
            keepTouched: true,
        },
    };
    const sessionValues = getSession(formKey) || options.defaultValues;

    const form = useForm({ ...baseFormOptions, ...options, defaultValues: sessionValues });

    const handleSubmit: ApplicationFormSubmitHandler = useCallback(
        (onValidCallback, customEventLabel) => async event => {
            event.preventDefault();
            const valid = await form.trigger();
            const formValues: FormValues = form.getValues();
            setSession(formValues);
            if (valid) {
                pushEvent({ eventLabel: customEventLabel || 'Next button click' });
                if (onValidCallback) onValidCallback();
            }
        },
        [pushEvent, setSession, form]
    );
    return {
        form,
        handleSubmit,
    };
};

export const ApplicationContext = createContext<ApplicationContextProps | undefined>(undefined);

export const useApplicationContext = (): ApplicationContextProps => {
    const context = useContext(ApplicationContext);
    if (!context) {
        throw new Error('useApplicationContext must be used within an ApplicationContextProvider');
    }
    return context;
};

const useApplicationData = <Schema>(key: ApplicationFormKeys): Schema | undefined => {
    const { getSession } = useApplication();
    const application = getSession(key);
    if (!application) return undefined;
    return application as Schema;
};

export const useApplicationPersonalInformation = () => {
    const application = useApplicationData<CompletePersonalInformationSchema>(ApplicationFormKeys.PERSONAL_INFORMATION);
    return application?.personalInformation;
};

export const useCashLikeFundDeclaration = () => {
    const application = useApplicationData<CashLikeFundDeclarationSchema>(
        ApplicationFormKeys.CASH_LIKE_FUND_DECLARATION
    );
    return application?.cashLikeFundDeclaration?.consent;
};

export const useRegularIncome = () => {
    const application = useApplicationData<RegularIncomeSchema>(ApplicationFormKeys.REGULAR_INCOME);
    return application?.regularIncome;
};

export const useScheme = () => {
    const application = useApplicationData<SchemeSchema>(ApplicationFormKeys.SCHEME);
    return application?.scheme;
};

export const useLumpSumOptions = () => {
    const application = useApplicationData<LumpSumOptionsSchema>(ApplicationFormKeys.LUMP_SUM_OPTIONS);
    return application?.lumpSumOptions;
};

export const useInvestmentPathwayCharges = (fundCode: DrawdownInvestmentPathwayOption) => {
    const {
        pensionValues: { remainderAfterLumpSum },
        withdrawalOption,
    } = useApplicationContext();
    const { investmentPathway, loading } = useSelectedInvestmentPathway(fundCode);
    const regularIncome = useRegularIncome();

    const totalExpenseRate = !loading ? investmentPathway?.totalExpenseRate?.toFixed(3) : '';

    const regularIncomeMonthlyValue = Number(regularIncome?.amount);

    const calculateRemainingPensionValue = () => {
        let remainingPensionValue = remainderAfterLumpSum || 0;

        switch (withdrawalOption) {
            case 'Lump sum':
                remainingPensionValue = remainderAfterLumpSum || 0;
                break;
            case 'Both':
                remainingPensionValue -= regularIncomeMonthlyValue;
                break;
            default:
                break;
        }

        return remainingPensionValue;
    };

    const remainingPensionValue = calculateRemainingPensionValue();
    const discount = remainingPensionValue < 25000 ? 0.3 : 0.5;

    return {
        discount,
        totalExpenseRate,
        totalCharge: !loading && totalExpenseRate ? (parseFloat(totalExpenseRate) - discount).toFixed(3) : '',
        loading,
    };
};

export const useApplicationSessionBack = (key: ApplicationBackRouteKeys) => {
    const {
        investmentOptions: { drawdown },
        withdrawalOption,
    } = useApplicationContext();

    switch (key) {
        case 'investment-options':
            return withdrawalOption === 'Regular income' || withdrawalOption === 'Both'
                ? '/application/regular-income'
                : '/application/lump-sum-options';

        case 'key-considerations-information':
            return drawdown === InvestmentOptions.CUSTOM
                ? '/application/drawdown/fund-options'
                : '/application/drawdown/investment-pathway-options';

        default:
            console.error('Unknown route key:', key);
            return '/';
    }
};

export const useDrawdownInvestmentPathwayFundCode = () => {
    const { getSession } = useApplication();
    const application = getSession(ApplicationFormKeys.DRAWDOWN_INVESTMENT_PATHWAY);
    if (!application) return undefined;
    const {
        drawdownInvestmentPathway: { fundCode },
    } = application as DrawdownInvestmentPathwayOptionSchema;
    return fundCode as DrawdownInvestmentPathwayOption;
};

export const CREATE_APPLICATION_RESPONSE = 'CREATE_APPLICATION_RESPONSE';
export const APPLICATION_DATE = 'APPLICATION_DATE';

export const useApplicationPDFData = (): ApplicationPDFProps => {
    const { getSession } = useApplication();
    const personalInformation = getSession(ApplicationFormKeys.PERSONAL_INFORMATION);

    const applicationContext = useApplicationContext();
    const fundCode = useDrawdownInvestmentPathwayFundCode();
    const { investmentPathway: drawdownPathway } = useSelectedInvestmentPathway(fundCode || undefined);
    return {
        ...applicationContext,
        drawdownPathway,
        personalInformation: personalInformation?.personalInformation,
        applicationDate: sessionStorage.getItem(APPLICATION_DATE) || '-',
    };
};
