import { FieldValues, UseFormReturn } from '@slal/ui/react-hook-form/core';
import { createContext, FormEventHandler } from 'react';
import { ApplicationContextProps, BaseFund, CompletePersonalInformationValues, CustomerDetailsResponse } from '~/types';

/**
 * Type representing a plain JavaScript object that can be serialized to JSON.
 */
export type DocumentType =
    | null
    | boolean
    | number
    | string
    | DocumentType[]
    | {
          [prop: string]: DocumentType;
      };

export type RestApiOptionsBase = {
    withCredentials?: boolean;
};

export interface ApiInput<Options> {
    /**
     * Path of the REST API.
     */
    path: string;
    /**
     * Options to overwrite the REST API call behavior.
     */
    options?: Options;
}

export type GetInput = ApiInput<RestApiOptionsBase>;
export type PostInput = GetInput & {
    body?: DocumentType | FormData;
};

export type UseRestApiRequestResponseBase = {
    data?: DocumentType;
    error: boolean;
    loading: boolean;
};

/**
 * Type representing an operation that can be canceled.
 *
 * @internal
 */
export interface Operation<Response> {
    response: Promise<Response>;
    cancel(abortMessage?: string): void;
}
interface ResponsePayload {
    blob(): Promise<Blob>;
    json(): Promise<DocumentType>;
    text(): Promise<string>;
}

type Headers = Record<string, string>;

/**
 * Basic response type of REST API.
 *
 * @internal
 */
export interface RestApiResponse {
    body: ResponsePayload;
    statusCode: number;
    headers: Headers;
}

export type UseRestApiGetRequestResponse = UseRestApiRequestResponseBase & {
    sendRequest: () => Promise<void>;
};

export type UseRestApiPostRequestResponse = UseRestApiRequestResponseBase & {
    fingerprint?: string;
    sendRequest: (data: DocumentType | FormData) => Promise<void>;
};

export type ApplicationFormSubmitHandler = (
    onValidCallback?: () => void,
    customEventLabel?: string
) => FormEventHandler;

export type UseApplicationFormReturn<FormValues extends FieldValues = FieldValues> = {
    form: UseFormReturn<FormValues>;
    handleSubmit: ApplicationFormSubmitHandler;
};

export type ApplicationPDFProps = {
    personalInformation?: CompletePersonalInformationValues;
    drawdownPathway?: BaseFund;
    applicationDate: string;
} & ApplicationContextProps;

export const UserContext = createContext<UseUserContextResponse | undefined>(undefined);

export enum UserContextState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    COMPLETE = 'COMPLETE',
}

export type UseUserContextResponse = UseRestApiRequestResponseBase & {
    data?: CustomerDetailsResponse;
    reset: () => void;
};
