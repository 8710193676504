import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';
import { NoticeProps } from './types';

export const StyledNoticeCard = styled.div<NoticeProps>`
    display: flex;
    margin-bottom: ${({ theme: { space } }) => space[6]};
    margin-top: ${({ theme: { space } }) => space[4]};
    border: ${({ theme, level }) => (!level || level === 'info' ? 'none' : `2px solid ${theme.colors[level]}`)};
    background-color: ${({ theme, level }) => (!level || level === 'info' ? theme.colors.blue__secondary__00 : 'none')};
    color: ${({ theme, level }) => (!level || level === 'info' ? theme.colors.primaryText : theme.colors[level])};
    padding: ${({ theme: { space } }) => space[4]};
    width: fit-content;

    svg {
        color: ${({ theme, level }) =>
            !level || level === 'info' ? theme.colors.blue__secondary__80 : theme.colors[level]};
        min-width: 20px;
        margin-right: ${({ theme: { space } }) => space[3]};
        margin-top: -3px;

        ${breakpointUp.md`
            min-width: 24px;  
        `}
    }
`;
