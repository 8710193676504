import { HeadingLabelDescription, HeadingLabelTitle, HeadingLabelWrapper } from './styles';

export const HeadingLabel = ({ title, description }: { title: string; description: string }) => {
    return (
        <HeadingLabelWrapper>
            <HeadingLabelTitle tag="strong">{title}</HeadingLabelTitle>
            <HeadingLabelDescription>{description}</HeadingLabelDescription>
        </HeadingLabelWrapper>
    );
};
