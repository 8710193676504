import { styled } from '@slal/ui';
import { Accordion } from '@slal/ui/display';
import { P } from '@slal/ui/typography';
import { Li } from '~/components';
import { Button } from '@slal/ui/buttons';

export const StyledEstimatedTimelines = styled(P)`
    margin-top: ${({ theme }) => theme.space[5]};
    color: ${({ theme }) => theme.colors.neutral__dark__20};
    font-size: 20px;
    font-weight: 600;
`;

export const ListItem = styled(Li)`
    color: black;
    margin-bottom: ${({ theme }) => theme.space[5]};
`;

export const StyledAccordion = styled(Accordion)`
    border: none;
    background: none;
    margin-bottom: ${({ theme }) => theme.space[8]};

    div {
        padding-left: 0;
        padding-bottom: 0;
    }

    h4 {
        font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    }
`;

export const StyledPDFDownloadButton = styled(Button)`
    margin-bottom: ${({ theme }) => theme.space[5]};
`;
