import { ComponentPropsWithoutRef } from 'react';
import { StyledLi, StyledLiText, StyledOl, StyledUl } from './styles';
import { TextProps } from '@slal/ui/typography';

export const Li = ({
    children,
    markdown = true,
    ...otherProps
}: ComponentPropsWithoutRef<'li'> & { markdown?: TextProps['markdown'] }) => (
    <StyledLi {...otherProps}>
        <StyledLiText markdown={markdown}>{children}</StyledLiText>
    </StyledLi>
);

export const Ul = ({ children, ...otherProps }: ComponentPropsWithoutRef<'ul'>) => (
    <StyledUl {...otherProps}>{children}</StyledUl>
);

export const Ol = ({ children, ...otherProps }: ComponentPropsWithoutRef<'ol'>) => (
    <StyledOl {...otherProps}>{children}</StyledOl>
);
