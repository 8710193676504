import { styled } from '@slal/ui';
import { Article } from '~/components';

export const StyledPotReview = styled(Article)`
    ol {
        li {
            margin-bottom: ${({ theme }) => theme.space[5]};
            line-height: ${({ theme }) => theme.lineHeights.copy};
            &::marker {
                font-family: ${({ theme }) => theme.fonts.headings.family};
                font-weight: ${({ theme }) => theme.fonts.headings.fontWeights.bold};
                color: ${({ theme }) => theme.colors.primaryText};
            }
            strong {
                font-family: ${({ theme }) => theme.fonts.headings.family};
            }
            br {
                display: block;
                margin-top: ${({ theme }) => theme.space[4]};
                content: ' ';
            }
        }
    }
`;
