import { isEqual } from 'lodash';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useApplication, UserContext, UserContextState, useRestApiGetRequest, UseUserContextResponse } from '~/hooks';
import { ApplicationFormKeys, ApplicationSchema, CustomerDetailsResponse } from '~/types';
import { getUserContextState, removeUserContextState, setUserContextState } from './utils';

export const UserContextProvider = ({ children }: PropsWithChildren) => {
    const { getSession, setSession } = useApplication();

    const reset = useCallback(() => {
        removeUserContextState();
    }, []);

    const [userContext, setUserContext] = useState<UseUserContextResponse>({
        data: undefined,
        error: false,
        loading: false,
        reset,
    });

    const { data, error, loading, sendRequest } = useRestApiGetRequest({
        path: '/customer-details',
        // options: {
        //     withCredentials: true,
        // },
    });

    useEffect(() => {
        if (getUserContextState() === UserContextState.INIT) {
            setUserContextState(UserContextState.LOADING);
            sendRequest();
            setUserContext(userContext => ({
                ...userContext,
                loading: true,
            }));
        }
    }, [setUserContext, sendRequest]);

    useEffect(() => {
        let userContextState = getUserContextState();

        switch (userContextState) {
            case UserContextState.COMPLETE:
                if (!data) {
                    setUserContext(userContext => ({
                        ...userContext,
                        data: {
                            ...getSession(ApplicationFormKeys.PENSION_TRANSFER),
                            ...getSession(ApplicationFormKeys.PERSONAL_INFORMATION),
                        } as CustomerDetailsResponse,
                    }));
                }
                break;
            case UserContextState.ERROR:
                if (!userContext.error) {
                    setUserContext(userContext => ({
                        ...userContext,
                        error: true,
                    }));
                }
                break;
            case UserContextState.LOADING:
                if (
                    isEqual(
                        { data: userContext.data, error: userContext.error, loading: userContext.loading },
                        { data, error, loading }
                    )
                )
                    break;
                if (data) {
                    userContextState = UserContextState.COMPLETE;
                    setUserContextState(userContextState);
                    setSession(data as Partial<ApplicationSchema>);
                }
                if (error) {
                    userContextState = UserContextState.ERROR;
                    setUserContextState(userContextState);
                }

                setUserContext(userContext => ({
                    ...userContext,
                    data: data as CustomerDetailsResponse,
                    error,
                    loading: userContextState === UserContextState.LOADING,
                }));

                break;
            default:
        }
    }, [data, error, setSession, setUserContext, userContext]);

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};
