import { InvestmentOptions } from '~/types';
import { HeadingLabel } from '~/components';

export const options = [
    {
        label: (
            <HeadingLabel
                title="Ready made"
                description="Choose 1 of 4 ready made investment pathways that reflects your plans for the next 5 years."
            />
        ),
        value: InvestmentOptions.DEFAULT,
    },
    {
        label: (
            <HeadingLabel title="Choose your own investments" description="Choose from over 300 investment options." />
        ),
        value: InvestmentOptions.CUSTOM,
    },
];

export const initialValues = {
    drawdownInvestment: {
        option: '',
    },
};
