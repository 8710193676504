import { styled } from '@slal/ui';
import { AccordionSection } from '@slal/ui/display';
import { Li, Ul } from '@slal/ui/typography';

export const StyledUl = styled(Ul)`
    padding-left: 0;
`;

export const StyledLi = styled(Li)`
    list-style: none;

    span {
        display: flex;
        gap: ${({ theme: { space } }) => space[4]};
        align-items: center;
    }
    svg {
        min-width: 18px;
        min-height: 18px;
    }
`;

export const StyledAccordionSection = styled(AccordionSection)`
    h5 {
        margin-bottom: ${({ theme: { space } }) => space[4]};
    }
`;
