import { Heading } from '@slal/ui/typography';
import { getUniqueId } from '~/utils';
import {
    StyledNotificationContent,
    StyledNotificationCard,
    StyledWarningIcon,
    StyledInformationIcon,
    StyledDangerIcon,
    StyledSuccessIcon,
} from './styles';
import { NotificationProps } from './types';

const Icon = ({ level }: NotificationProps) => {
    switch (level) {
        case 'danger':
            return <StyledDangerIcon />;
        case 'success':
            return <StyledSuccessIcon />;
        case 'warning':
            return <StyledWarningIcon />;
        default:
            return <StyledInformationIcon />;
    }
};

export const Notification = ({ heading, children, ...otherProps }: NotificationProps) => {
    const id = heading ? getUniqueId('notification-') : undefined;

    const { level } = otherProps;

    return (
        <StyledNotificationCard
            role={level === 'info' ? 'complementary' : 'alert'}
            aria-labelledby={id}
            aria-live={level === 'info' ? 'polite' : 'assertive'}
            {...otherProps}
        >
            <Icon level={level} />
            <StyledNotificationContent aria-label="Notification content">
                {heading && (
                    <Heading id={id} level={5}>
                        {heading}
                    </Heading>
                )}
                {children}
            </StyledNotificationContent>
        </StyledNotificationCard>
    );
};
