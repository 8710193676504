import { Font } from '@react-pdf/renderer';

Font.register({
    family: 'Arial',
    fonts: [
        {
            src: '/fonts/OpenSans-Regular.ttf',
            fontWeight: 'normal',
        },
        {
            src: '/fonts/OpenSans-Bold.ttf',
            fontWeight: 'bold',
        },
    ],
});

Font.register({
    family: 'Ubuntu',
    fonts: [
        {
            src: '/fonts/Ubuntu-Regular.ttf',
            fontWeight: 'normal',
        },
        {
            src: '/fonts/Ubuntu-Bold.ttf',
            fontWeight: 'bold',
        },
    ],
});
