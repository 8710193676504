import * as yup from 'yup';
import {
    AdditionalDetailsSchema,
    ApplicationSchema,
    BankDetailsSchema,
    DataConsentSchema,
    DeclarationSchema,
    DrawdownInvestmentOptionsSchema,
    FundAllocation,
    SavingsFundAllocationsSchema,
    GuidanceSchema,
    LumpSumOptions,
    LumpSumOptionsSchema,
    PensionSchema,
    PersonalInformationSchema,
    RegularIncomeSchema,
    SavingsInvestmentOptionsSchema,
    SavingsInvestmentPathway,
    WithdrawalOptionsSchema,
    DrawdownFundAllocationsSchema,
    DrawdownInvestmentPathwayOption,
    DrawdownInvestmentPathwayOptionSchema,
    InvestmentOptions,
    KeyConsiderationsSchema,
    CompletePersonalInformationSchema,
    CashLikeFundDeclarationSchema,
} from '~/types';
import { formatToGBP } from '~/utils';

export const validationMessages = {
    accountNumber: 'A valid account number is required',
    accountNumberMax: 'The value must be a maximum of 8 numbers',
    date: 'A valid date is required',
    email: 'A valid email address is required',
    fundAllocationRequired: 'You must select your fund options',
    fundAllocationValue: 'One or more of your percentage values is not a number between 1 and 100',
    fundAllocationsTotal: 'The total fund allocation must equal 100%',
    fundAllocationsMax: 'You can only invest in 12 funds at any one time',
    lumpSumValue: 'A value between £1 and your maximum amount is required',
    name: 'A valid name is required',
    nameMax: 'The value must be a maximum of 35 characters',
    niNumber: 'A valid National Insurance number is required',
    notYesValue: 'The value cannot be "yes"',
    pensionValue: 'A value between £750-£750,000 is required',
    maxPensionValue:
        'As you have more than £750,000 in your pension, you need to call us on 0345 266 8013 to complete this transfer. Call charges will vary.',
    pensionTransferValue: 'A value between £750 and your current value is required',
    phoneNumber: 'A valid phone number is required',
    phoneNumberMin: 'The value must be a minumum of 11 numbers',
    phoneNumberMax: 'The value must be a maximum of 15 numbers',
    postcode: 'A valid UK postcode is required',
    regularPaymentDay: 'A value between 1 and 28 is required',
    required: 'This is a required field',
    sortCode: 'A valid sort code is required',
};

const regexPatterns = {
    name: /^[A-Za-z\s]+$/,
    number: /^\d+$/,
    niNumber: /^(?!BG|GB|KN|NK|NT|TN|ZZ)(?![DFIQUV])[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]{1}$/,
    postcode: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/,
};

const guidance = yup.object().shape({
    guidanceReceived: yup.string().required(validationMessages.required),
});

export const guidanceValidationSchema: yup.ObjectSchema<GuidanceSchema> = yup.object().shape({
    guidance,
});

const optionsSchema = yup.object().shape({
    option: yup.string().required(validationMessages.required),
});

export const dataConsentSchema: yup.ObjectSchema<DataConsentSchema> = yup.object().shape({
    dataConsent: optionsSchema,
});

const personalInformation = yup.object().shape({
    title: yup.string().required(validationMessages.required),
    firstName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    lastName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    dateOfBirth: yup
        .string()
        .test(
            'is-iso-date',
            validationMessages.date,
            (value: string | undefined) => !!value && /(\d{4})-(\d{2})-(\d{2})/.test(value!)
        )
        .required(validationMessages.required),
    emailAddress: yup.string().email(validationMessages.email).required(validationMessages.required),
    phoneNumber: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.number, validationMessages.phoneNumber)
        .min(11, validationMessages.phoneNumberMin)
        .max(15, validationMessages.phoneNumberMax),
});

export const personalInformationValidationSchema: yup.ObjectSchema<PersonalInformationSchema> = yup.object().shape({
    personalInformation,
});

const additionalDetails = yup.object().shape({
    addressLineOne: yup.string().required(validationMessages.required),
    addressLineTwo: yup.string().required(validationMessages.required),
    addressLineThree: yup.string().optional(),
    postcode: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.postcode, validationMessages.postcode),
    nationalInsuranceNumber: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.niNumber, validationMessages.niNumber),
    maritalStatus: yup.string().required(validationMessages.required),
});

export const additionalDetailsSchema: yup.ObjectSchema<AdditionalDetailsSchema> = yup.object().shape({
    personalInformation: additionalDetails,
});

export const completePersonalInformation = personalInformation.concat(additionalDetails);

export const completePersonalInformationSchema: yup.ObjectSchema<CompletePersonalInformationSchema> = yup
    .object()
    .shape({
        personalInformation: completePersonalInformation,
    });

export const pension = yup.object().shape({
    policyNumber: yup.string().required(validationMessages.required),
    provider: yup.string().required(validationMessages.required),
    value: yup
        .number()
        .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
        .required(validationMessages.required)
        .min(750, validationMessages.pensionValue)
        .max(750000, validationMessages.maxPensionValue),
    specifiedProvider: yup.string().when('provider', {
        is: (value: string) => value === 'Other provider',
        then: () => yup.string().required(validationMessages.required),
    }),
    transferAmountOption: yup.string().required(validationMessages.required),
    specifiedTransferAmount: yup.string().when('transferAmountOption', {
        is: (value: string) => value === LumpSumOptions.SPECIFY,
        then: () =>
            yup
                .number()
                .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
                .required(validationMessages.required)
                .min(750, validationMessages.pensionTransferValue)
                .max(yup.ref('value'), validationMessages.pensionTransferValue),
    }),
    moneyWithdrawn: yup
        .string()
        .required(validationMessages.required)
        .test('is-not-yes', validationMessages.notYesValue, (value: string | undefined) => value !== 'Yes'),
    declaration: yup.boolean().oneOf([true], validationMessages.required).required(),
});

export const pensionSchema: yup.ObjectSchema<PensionSchema> = yup.object().shape({
    pension,
});

const withdrawal = yup.object().shape({
    option: yup.string().required(validationMessages.required),
});

export const withdrawalOptionsValidationSchema: yup.ObjectSchema<WithdrawalOptionsSchema> = yup.object().shape({
    withdrawal,
});

const lumpSumOptions = (maximumLumpSum: number) =>
    yup.object().shape({
        option: yup.string().required(validationMessages.required),
        specifiedAmount: yup.string().when('option', {
            is: (value: string) => value === LumpSumOptions.SPECIFY,
            then: () =>
                yup
                    .number()
                    .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
                    .required(validationMessages.required)
                    .min(1, validationMessages.lumpSumValue)
                    .max(maximumLumpSum, validationMessages.lumpSumValue),
        }),
    });

export const lumpSumOptionsValidationSchema = (maximumLumpSum: number): yup.ObjectSchema<LumpSumOptionsSchema> =>
    yup.object().shape({
        lumpSumOptions: lumpSumOptions(maximumLumpSum),
    });

const regularIncome = (maximumRegularIncome: number) => {
    const validationMessage = `A value between £1 and ${formatToGBP(maximumRegularIncome)} is required so it lasts for a year`;
    return yup.object().shape({
        amount: yup
            .number()
            .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
            .required(validationMessages.required)
            .min(1, validationMessage)
            .max(maximumRegularIncome, validationMessage),
        day: yup
            .number()
            .transform((value: string | undefined) => (value ? parseFloat(value) : undefined))
            .required(validationMessages.required)
            .min(1, validationMessages.regularPaymentDay)
            .max(28, validationMessages.regularPaymentDay),
    });
};

export const regularIncomeValidationSchema = (maximumRegularIncome: number): yup.ObjectSchema<RegularIncomeSchema> =>
    yup.object().shape({
        regularIncome: regularIncome(maximumRegularIncome),
    });

const investmentOption = yup.object().shape({
    option: yup.string().required(validationMessages.required),
});

export const SavingsInvestmentOptionsValidationSchema: yup.ObjectSchema<SavingsInvestmentOptionsSchema> = yup
    .object()
    .shape({
        savingsInvestment: investmentOption,
    });

const savingsInvestmentPathway: yup.ObjectSchema<SavingsInvestmentPathway> = yup.object().shape({
    fundCode: yup.string().required(),
    fundName: yup.string().required(),
});
const drawdownInvestmentPathway = yup.object().shape({
    fundCode: yup.string().oneOf(Object.values(DrawdownInvestmentPathwayOption)).required(validationMessages.required),
    fundName: yup.string().required(validationMessages.required),
});

export const drawdownInvestmentPathwayOptionsValidationSchema: yup.ObjectSchema<DrawdownInvestmentPathwayOptionSchema> =
    yup.object().shape({
        drawdownInvestmentPathway,
    });

export const drawdownInvestmentOptionsValidationSchema: yup.ObjectSchema<DrawdownInvestmentOptionsSchema> = yup
    .object()
    .shape({
        drawdownInvestment: investmentOption,
    });

const fundAllocations = yup
    .array()
    .min(1, validationMessages.fundAllocationRequired)
    .max(12, validationMessages.fundAllocationsMax)
    .test('fund-allocation-values', validationMessages.fundAllocationValue, (fundAllocations?: FundAllocation[]) => {
        if (!fundAllocations) return false;
        for (const { allocation } of fundAllocations) {
            const value = parseFloat(allocation);
            if (!value || value > 100) return false;
        }
        return true;
    })
    .test('fund-allocation-total', validationMessages.fundAllocationsTotal, (fundAllocations?: FundAllocation[]) => {
        let total = 0;
        if (!fundAllocations) return false;
        for (const { allocation } of fundAllocations) {
            const value = parseFloat(allocation);
            total = total + value;
        }
        return total === 100;
    });

export const savingsFundAllocationValidationSchema: yup.ObjectSchema<SavingsFundAllocationsSchema> = yup
    .object()
    .shape({
        savingsFundAllocations: fundAllocations,
    });

export const drawdownFundAllocationValidationSchema: yup.ObjectSchema<DrawdownFundAllocationsSchema> = yup
    .object()
    .shape({
        drawdownFundAllocations: fundAllocations,
    });

export const keyConsiderationsValidationSchema = (key: string): yup.ObjectSchema<KeyConsiderationsSchema> =>
    yup.object().shape({
        keyConsiderations: yup.object().shape({
            [key]: yup.string().required(validationMessages.required),
        }),
    });

const bankDetails = yup.object().shape({
    accountHolderName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    sortCode: yup
        .string()
        .required(validationMessages.required)
        .test(
            'is-sort-code',
            validationMessages.sortCode,
            (value: string | undefined) => !!value && /(\d{2})-(\d{2})-(\d{2})/.test(value!)
        ),
    bankName: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.name, validationMessages.name)
        .max(35, validationMessages.nameMax),
    accountNumber: yup
        .string()
        .required(validationMessages.required)
        .matches(regexPatterns.number, validationMessages.accountNumber)
        .max(8, validationMessages.accountNumberMax),
    buildingSocietyRollNumber: yup.string().optional(),
});

export const bankDetailsValidationSchema: yup.ObjectSchema<BankDetailsSchema> = yup.object().shape({
    bankDetails,
});

const declaration = yup.object().shape({
    consent: yup.boolean().oneOf([true], validationMessages.required).required(),
});

const applicationDeclaration = yup.object().shape({
    applicationCopyConsent: yup.boolean().oneOf([true], validationMessages.required).required(),
    informationVerificationConsent: yup.boolean().oneOf([true], validationMessages.required).required(),
});

export const declarationValidationSchema: yup.ObjectSchema<DeclarationSchema> = yup
    .object()
    .shape({ declaration: applicationDeclaration });

export const cashLikeFundDeclarationValidationSchema: yup.ObjectSchema<CashLikeFundDeclarationSchema> = yup
    .object()
    .shape({ cashLikeFundDeclaration: declaration });

export const applicationSchema = (
    maximumLumpSum: number,
    maximumRegularIncome: number
): yup.ObjectSchema<ApplicationSchema> =>
    yup
        .object()
        .shape({
            bankDetails,
            dataConsent: optionsSchema,
            declaration: applicationDeclaration,
            drawdownInvestment: investmentOption,
            drawdownInvestmentPathway: yup.object().when('drawdownInvestment.option', {
                is: (value: string) => value === InvestmentOptions.DEFAULT,
                then: () => drawdownInvestmentPathway,
            }),
            drawdownFundAllocations: yup.array().when('drawdownInvestment.option', {
                is: (value: string) => value === InvestmentOptions.CUSTOM,
                then: () => fundAllocations,
            }),
            guidance,
            keyConsiderations: yup.object().shape({
                mainPlan: yup.string().required(validationMessages.required),
                healthIssues: yup.string().required(validationMessages.required),
                investmentScams: yup.string().required(validationMessages.required),
                outstandingDebts: yup.string().required(validationMessages.required),
                meansTestedBenefits: yup.string().required(validationMessages.required),
                continueContributing: yup.string().required(validationMessages.required),
                stillInvested: yup.string().required(validationMessages.required),
                remainingPension: yup.string().required(validationMessages.required),
            }),
            lumpSumOptions: lumpSumOptions(maximumLumpSum),
            pension,
            personalInformation: completePersonalInformation,
            regularIncome: yup.object().when('withdrawal.option', {
                is: (value: string) => value === 'Both',
                then: () => regularIncome(maximumRegularIncome),
            }),
            savingsInvestment: optionsSchema.optional().default(undefined),
            savingsInvestmentPathway: savingsInvestmentPathway.optional().default(undefined),
            savingsFundAllocations: yup
                .array()
                .when('savingsInvestment.option', {
                    is: (value: string) => value === InvestmentOptions.CUSTOM,
                    then: () => fundAllocations,
                })
                .optional()
                .default(undefined),
            withdrawal,
        })
        .required(validationMessages.required);
